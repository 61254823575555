import QRCode from 'qrcode'
import api from "../api/Api"
import { routes } from "../routes/Routes"
import { store } from "../store/store"
import { setArLoading, setArLink, setImageQRInterior, setImageQRExterior} from '../slices/arSlice'
import { setSnackbarError } from '../slices/snackbarSlice'
import { getErrorMessage } from '../api/ApiDataHelper'

const getAndroidIntent = (fileParam) => {
  const resourceUrlAndroid = fileParam + '.glb'
  const androidArProps = [
    "&resizable=false",
    "&enable_vertical_placement=false",
    "&mode=ar_preferred"
  ].join("");
  const androidIntent = "intent://arvr.google.com/scene-viewer/1.0?file=" + resourceUrlAndroid + androidArProps + "#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.ar.core;end;";
  return androidIntent
}

const getIosUrl = (fileParam) => {
  const resourceUrlIos = fileParam + '.usdz'
  const iOSArProps = [
    "#allowsContentScaling=0"
  ].join("");
  const iosUrl = resourceUrlIos + iOSArProps
  return iosUrl
}

const handleGenerateAR = async (data, onError) => {
  store.dispatch(setArLoading(true));
  try {
    let generateARModelInside = null;
    let generateARModelOutside = null;
    // INSIDE
    if(store.getState().ar.arLoading){

      data.isOutside = false;
      generateARModelInside = await api.generateARModel(data)
    }
    if(store.getState().ar.arLoading){
    // OUTSIDE
      data.isOutside = true;
      generateARModelOutside = await api.generateARModel(data)
    }
    if (generateARModelInside !== null && generateARModelOutside !== null) {
      const arModelInside = generateARModelInside
      const arModelOutside = generateARModelOutside
      const arModelLinkInside = encodeURIComponent(arModelInside)
      const arModelLinkOutside = encodeURIComponent(arModelOutside)
      const arLandingUrlInside = document.location.origin + process.env.PUBLIC_URL + `${routes.ar}?file=` + encodeURIComponent(arModelInside)
      const arLandingUrlOutside = document.location.origin + process.env.PUBLIC_URL + `${routes.ar}?file=` + encodeURIComponent(arModelOutside)
      // console.log('arLandingUrl', arLandingUrl)
      const qrCodeInside = await QRCode.toDataURL(arLandingUrlInside, { width: 200 })
      const qrCodeOutside = await QRCode.toDataURL(arLandingUrlOutside, { width: 200 })
      // console.log({ arLandingUrl, qrCode, arModel });
      store.dispatch(setArLink({
        inside: arModelLinkInside,
        outside: arModelLinkOutside
      }));
      store.dispatch(setImageQRInterior(qrCodeInside));
      store.dispatch(setImageQRExterior(qrCodeOutside));
      return {
        arLandingUrlInside,
        arLandingUrlOutside,
        qrCodeInside,
        qrCodeOutside,
        arModelInside,
        arModelOutside,
        arModelLinkInside,
        arModelLinkOutside }
    } else {
      return null;
    }
  }
  catch (error) {
    store.dispatch(setSnackbarError(getErrorMessage(error)))
    if(onError){
      onError();
    }
  }finally {
    store.dispatch(setArLoading(false));
  }
}

export {
  getAndroidIntent,
  getIosUrl,
  handleGenerateAR,
}
