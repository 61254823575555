import { Container } from "@mui/material"
import { Outlet } from "react-router-dom"
import AppBar from "./AppBar"

export default function PageLayout() {

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: "100svh",
        position:"relative",
        overflow: "hidden"
      // "@media print": {
      //   display:"none"
      // }
      }}
      // sx={{ pt: appBarHeight + "px" }}
    >
      <AppBar />
      <Outlet />
    </Container>
  )
}
