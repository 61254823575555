import { createSlice } from '@reduxjs/toolkit'
import { deleteAuthToken } from '../helpers/AuthHelper'
import { viewerInstance } from '../helpers/ViewerHelper'
import { resetInitHomePageState } from './initHomepageSlice'
import { resetErrorPageState } from './errorSlice'
// import { resetInitViewerPageState } from './initViewerPageSlice'
// import { resetViewerControls } from './viewerControlsSlice'
// import { resetAuthState } from './authSlice'
// import { resetCartState } from './cartSlice'
// import { resetCatalogState } from './catalogSlice'
// import { resetViewerState } from './viewerSlice'
// import { resetWizardState } from './wizardSlice'
// import { resetOrdersState } from './ordersSlice'
// import { resetNewsState } from './newsSlice'
// import { resetDashboardState } from './dashboardSlice'
// import { resetRoomState } from './roomSlice'
// import { resetInitCatalogPageState } from './initCatalogPageSlice'
// import { resetInitDashboardPageState } from './initDashboardPageSlice'
// import { resetInitOrdersPageState } from './initOrdersPageSlice'
// import { resetInitCartPageState } from './initCartPageSlice'
// import { disableAccessoryMode, resetAccessoryState } from './accessorySlice'
// import { resetVolumeState } from './volumeSlice'
// import { resetAttributeState } from './attributeSlice'
// import { resetViewsState } from './viewsSlice'

const initialState = {}

export const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {}
})

// export const {} = resetSlice.actions

export const resetStateForNewOrder = () => (dispatch, getState) => {
  const { accessoryModeEnabled } = getState().accessory
  if (accessoryModeEnabled)
    // dispatch(disableAccessoryMode(false))
  if (viewerInstance) {
    viewerInstance.clearScene()
    // dispatch(resetViewerControls())
  }
  // dispatch(resetCartState())
  // dispatch(resetWizardState())
  // dispatch(resetAttributeState())
  // dispatch(resetRoomState())
  // dispatch(resetAccessoryState())
  // dispatch(resetVolumeState())
  // dispatch(resetViewerState())
}

export const resetAppState = () => async (dispatch, getState) => {
  deleteAuthToken()
  // dispatch(resetAuthState())
  if (viewerInstance) {
    viewerInstance.clearScene()
    // dispatch(resetViewerControls())
  }
  // dispatch(resetCartState())
  // dispatch(resetCatalogState())
  // dispatch(resetNewsState())
  // dispatch(resetDashboardState())
  // dispatch(resetOrdersState())
  // dispatch(resetViewerState())
  // dispatch(resetAttributeState())
  // dispatch(resetWizardState())
  // dispatch(resetRoomState())
  // dispatch(resetAccessoryState())
  // dispatch(resetVolumeState())
  // dispatch(resetViewsState())
  dispatch(resetInitHomePageState())
  // dispatch(resetInitDashboardPageState())
  // dispatch(resetInitOrdersPageState())
  // dispatch(resetInitCartPageState())
  // dispatch(resetInitCatalogPageState())
  // dispatch(resetInitViewerPageState())
  dispatch(resetErrorPageState())
}

export default resetSlice.reducer
