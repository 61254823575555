import { routes } from "../routes/Routes"
import { setNavigate } from "../slices/navigationSlice"
import { store } from "../store/store"

export const toPage = page => store.dispatch(setNavigate(page))
export const toHomePage = () => store.dispatch(setNavigate(routes.home))

export const toLoginPage = () => store.dispatch(setNavigate(routes.login))
export const toViewerPage = () => store.dispatch(setNavigate(routes.viewer))
export const toPasswordRecoverPage = () => store.dispatch(setNavigate(routes.passwordRecover))
export const toPasswordChangePage = () => store.dispatch(setNavigate(routes.passwordChange))
export const toARPage = () => store.dispatch(setNavigate(routes.ar))
export const toUIComponentsPage = () => store.dispatch(setNavigate(routes.UIComponents))

export const onHomePage = () => window.location.pathname === routes.home
export const onViewerPage = () => window.location.pathname === routes.viewer
export const onPasswordRecoverPage = () => window.location.pathname === routes.passwordRecover
export const onPasswordChangePage = () => window.location.pathname === routes.passwordChange
export const onARPage = () => window.location.pathname === routes.ar
export const onUIComponentsPage = () => window.location.pathname === routes.UIComponents
