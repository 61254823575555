import { createSlice } from '@reduxjs/toolkit'

export const snackbarVariants = {
  default: 'default',
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info'
}

const initialState = {
  enabled: false,
  message: '',
  variant: snackbarVariants.default,
  persist: false
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    unsetSnackbar: () => initialState,
    setSnackBarState: (state, action) => {
      const { message, variant, persist } = action.payload
      state.enabled = true
      state.message = message
      state.variant = variant
      state.persist = persist
    }
  }
})

export const {
  unsetSnackbar,
  setSnackBarState,
} = snackbarSlice.actions

export const setSnackbarDefault = (message, persist = false) => (dispatch, getState) => {
  const variant = snackbarVariants.default
  dispatch(setSnackBarState({ message, persist, variant }))
}

export const setSnackbarError = (message, persist = false) => (dispatch, getState) => {
  const variant = snackbarVariants.error
  dispatch(setSnackBarState({ message, persist, variant }))
}

export const setSnackbarSuccess = (message, persist = false) => (dispatch, getState) => {
  const variant = snackbarVariants.success
  dispatch(setSnackBarState({ message, persist, variant }))
}

export const setSnackbarWarning = (message, persist = false) => (dispatch, getState) => {
  const variant = snackbarVariants.warning
  dispatch(setSnackBarState({ message, persist, variant }))
}

export const setSnackbarInfo = (message, persist = false) => (dispatch, getState) => {
  const variant = snackbarVariants.info
  dispatch(setSnackBarState({ message, persist, variant }))
}

export default snackbarSlice.reducer
