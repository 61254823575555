
const routes = {
  home: '/',
  ar: '/ar-landing',
  UIComponents: '/ui-components'
}

export {
  routes
}
