import { Box, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import platformDetect from "platform-detect"
import { getAndroidIntent, getIosUrl } from "../helpers/ARHelper"
import { useState } from "react"
import { getAssetVideoUrl } from "../api/ApiDataHelper"
import { useTranslation } from "react-i18next"

export default function ARLanding(props) {
  const searchParams = useSearchParams()
  const fileParam = searchParams[0].get("file")

  const [notSupported, setNotSupported] = useState(false)

  const { t } = useTranslation()

  const handleOpenAR = () => {
    if (platformDetect.android) {
      const androidIntent = getAndroidIntent(fileParam)
      window.open(androidIntent, '_blank')
    }
    else if (platformDetect.ios) {
      const iosUrl = getIosUrl(fileParam)
      window.open(iosUrl, '_blank')
    }
    else {
      setNotSupported(true)
    }
  }

  if (!fileParam) {
    return (
      <Typography color='error' textAlign='center' mt={4}>
        {t("label_ar_landing_file_missing")}
      </Typography>
    )
  }

  if (notSupported) {
    return (
      <Typography color='error' textAlign='center' mt={4}>
        {t("label_ar_landing_platform_not_supported")}
      </Typography>
    )
  }

  return (
    <Box textAlign='center' sx={{
      height: "100svh",
      display: "flex",
      flexDirection: "column",
      p: 2,
      overflow:"hidden",

    }}>
      <Typography variant='h5'>
        {t("lable_ar_landing_title")}
      </Typography>
      <Box
        color='primary'
        onClick={handleOpenAR}
        sx={{
          cursor: "pointer",
          maxWidth: "100%",
          maxHeight: "100%",
          flexGrow: 0,
          flexShrink: 1,
          display:"flex",
          alignItems: "center",
          justifyContent: "center",
          video: {
            pointerEvents: "none",
            maxWidth: "100%",
            maxHeight: "100%",
            aspectRatio: 1,
          }
        }}
      >
        <video autoPlay muted loop>
          <source src={getAssetVideoUrl('Cube_comp.mp4')} />
        </video>

      </Box>
    </Box >
  )
}
