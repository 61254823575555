import { createSlice } from '@reduxjs/toolkit';
import { setLongLogo, setMenuMobileOpen } from "./themeSlice";
import { steps } from "../helpers/StepsHelper";
// import placeholderImage from "../resources/placeholder.png";
import { changeIsFlipped, changeCamera, updateModelSize, loadDefault, changeMaterial, changeAccessories, setDoorOutsideMaterial, setDoorInsideMaterial } from './viewerSlice';
import { setSnackbarInfo } from './snackbarSlice';
// import { useTranslation } from 'react-i18next';
import i18n from '../helpers/i18n';

let timer;

const initialState = {
  preselectedModel: null,
  currentStep: 0,
  configurationWidth: 90,
  configurationHeight: 200,
  configurationHinge: 0,
  configurationVisibleHingeSupported: true,
  configurationSide: 0,
  configurationExterior_type: 2,
  configurationExterior_materialType: "label_Tekno_Material",
  configurationExterior_material: "Tekno_22",
  configurationInterior_materialType: "label_PVC_Material",
  configurationInterior_material: "RAL_9010",
  configurationHandleExternal: 0,
  configurationHandleInternal: 0,
  configurationAutoCloser: true,
  configurationAccessories: false,
  step2error: false,
  step3error: false,
  step4error: false,
  step5error: false,
  step6error: false,
  step7error: false,
  compatibleProducts: [
    steps[12].content.compatibleProducts["sunny"],
    steps[12].content.compatibleProducts["sunnytop"],
  ],
  productSelected: 0,
  screenshotReady: false,
}

const configurationDefaultSunnyCom = {
  configurationHinge: 1,
  configurationExterior_type: 2,
  configurationExterior_materialType: "label_Aluminum_Material",
  configurationExterior_material: "Ral_9010_Grinz",
  configurationInterior_materialType: "label_PVC_Material",
  configurationInterior_material: "RAL_9010",
  configurationHandleExternal: 1,
  configurationHandleInternal: 1,
  configurationAccessories: false,
  doorModelName: "SUNNY-COM_L_1_C",
  doorAccessories: [
    "ManigliaQuadrataInterna",
    "ManiglioneQuadrato",
    "SerraturaQuadrata",
  ]
};

const configurationDefaultSunny = {
  configurationHinge: 0,
  configurationExterior_type: 2,
  configurationExterior_materialType: "label_Tekno_Material",
  configurationExterior_material: "Tekno_22",
  configurationInterior_materialType: "label_PVC_Material",
  configurationInterior_material: "RAL_9010",
  configurationHandleExternal: 0,
  configurationHandleInternal: 0,
  configurationAccessories: false,
  doorModelName: "SUNNY-L_1_C",
  doorAccessories: [
    "ManigliaRotondaInterna",
    "ManiglioneRotondo",
    "SerraturaRotonda",
  ]

};

const configurationDefaultHabitat = {
  configurationHinge: 1,
  configurationExterior_type: 0,
  configurationExterior_materialType: "label_Aluminum_Material",
  configurationExterior_material: "Ral_9010_Grinz",
  configurationInterior_materialType: "label_PVC_Material",
  configurationInterior_material: "RAL_9010",
  configurationHandleExternal: 1,
  configurationHandleInternal: 1,
  configurationAccessories: false,
  doorModelName: "SUNNY-HABITAT_L_0_C",
  doorAccessories: [
    "ManigliaQuadrataInterna",
    "ManiglioneQuadrato",
    "SerraturaQuadrata",
  ]

};


export const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    setPreselectedModel: (state, action) => {
      state.preselectedModel = action.payload
    }, setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setConfigurationWidth: (state, action) => {
      state.configurationWidth = action.payload;
    },
    setConfigurationHeight: (state, action) => {
      state.configurationHeight = action.payload;
    },
    setConfigurationHinge: (state, action) => {
      state.configurationHinge = action.payload;
    },
    setConfigurationVisibleHingeSupported: (state, action) => {
      state.configurationVisibleHingeSupported = action.payload
    },
    setConfigurationSide: (state, action) => {
      state.configurationSide = action.payload;
    },
    setConfigurationExterior_type: (state, action) => {
      state.configurationExterior_type = action.payload;
    },
    setConfigurationExterior_material: (state, action) => {
      state.configurationExterior_material = action.payload;
    },
    setConfigurationInterior_material: (state, action) => {
      state.configurationInterior_material = action.payload;
    },
    setConfigurationInterior_materialType: (state, action) => {
      state.configurationInterior_materialType = action.payload;
    },
    setConfigurationExterior_materialType: (state, action) => {
      state.configurationExterior_materialType = action.payload;
    },
    setConfigurationHandleExternal: (state, action) => {
      state.configurationHandleExternal = action.payload
    },
    setConfigurationHandleInternal: (state, action) => {
      state.configurationHandleInternal = action.payload
    },
    setConfigurationAutoCloser: (state, action) => {
      state.configurationAutoCloser = action.payload
    },
    setConfigurationAccessories: (state, action) => {
      state.configurationAccessories = action.payload;
    },
    setStep2error: (state, action) => {
      state.step2error = action.payload;
    },
    setStep3error: (state, action) => {
      state.step3error = action.payload;
    },
    setStep4error: (state, action) => {
      state.step4error = action.payload;
    },
    setStep5error: (state, action) => {
      state.step5error = action.payload;
    },
    setStep6error: (state, action) => {
      state.step6error = action.payload;
    },
    setStep7error: (state, action) => {
      state.step7error = action.payload;
    },
    setCompatibleProducts: (state, action) => {
      state.compatibleProducts = action.payload;
    },
    setProductSelected: (state, action) => {
      state.productSelected = action.payload;
    },
    setScreenshotReady: (state, action) => {
      state.screenshotReady = action.payload;
    }
  }
})

export const {
  setPreselectedModel,
  setCurrentStep,
  setConfigurationWidth,
  setConfigurationHeight,
  setConfigurationVisibleHingeSupported,
  setConfigurationHinge,
  setConfigurationSide,
  setConfigurationExterior_type,
  setConfigurationExterior_material,
  setConfigurationInterior_material,
  setConfigurationInterior_materialType,
  setConfigurationExterior_materialType,
  setConfigurationHandleExternal,
  setConfigurationHandleInternal,
  setConfigurationAutoCloser,
  setConfigurationAccessories,
  setStep2error,
  setProductSelected,
  setScreenshotReady,
  setCompatibleProducts
} = stepSlice.actions

export const updateCurrentStep = (step) => async (dispatch, getState) => {
  const state = getState()
  // console.log("updateCurrentStep", step, state.viewer.cameraIsMoving)
  if (!state.viewer.cameraIsMoving) {

    dispatch(setCurrentStep(step))
    dispatch(setMenuMobileOpen(false))
    if (step !== 0) {
      dispatch(setLongLogo(false))
    } else {
      dispatch(setLongLogo(true))
    }

    //SWITH BETWEEN INSIDE/OUTISE VIEW BASED ON STEP
    switch (step) {
      case 1:
        dispatch(changeCamera(false));
        break;
      case 2:
        dispatch(changeCamera(true));
        break;
      case 3:
        dispatch(changeCamera(false));
        break;
      case 4:
        dispatch(changeCamera(false));
        break;
      case 5:
        dispatch(changeCamera(true));
        break;
      case 6:
        dispatch(changeCamera(false));
        break;
      case 7:
        dispatch(changeCamera(true));
        break;
      case 8:
        dispatch(changeCamera(false));
        break;
      case 9:
        dispatch(changeCamera(true));
        break;
      case 11:
        dispatch(changeCamera(false));
        break;
      case 12:
        dispatch(changeCamera(false));
        break;
      case 13:
        dispatch(changeCamera(false));
        break;
      default:
        break;
    }
  }
}

export const updateState = (key, value, viewerUpdate = true) => (dispatch, getState) => {
  // console.log("updateState", key, value)
  const oldState = getState();
  let timeToWaitMillisecond = 250;

  if ((!oldState.viewer.cameraIsMoving && !oldState.viewer.isBusy) || !viewerUpdate) {
    switch (key) {
      case "configurationWidth":
        // Check min and max on step 2 for Width
        dispatch(setStep2error(value > steps[2].content.input_values[0].max_value || value < steps[2].content.input_values[0].min_value));
        dispatch(setConfigurationWidth(value))
        break
      case "configurationHeight":
        // Check min and max on step 2 for Height
        dispatch(setStep2error(value > steps[2].content.input_values[1].max_value || value < steps[2].content.input_values[1].min_value));
        dispatch(setConfigurationHeight(value))
        break
      case "configurationHinge":
        dispatch(setConfigurationHinge(value))
        if (viewerUpdate) {
          if (value === 0) {
            dispatch(loadDefault(configurationDefaultSunny));
            dispatch(setSnackbarInfo(i18n.t("label_info_changed_configuration")))
            Object.keys(configurationDefaultSunny).forEach((value) => {
              dispatch(updateState(value, configurationDefaultSunny[value], false));
            })
            dispatch(updateCompatibleProducts(["sunny", "sunnytop"]))
          } else {
            dispatch(loadDefault(configurationDefaultSunnyCom));
            dispatch(setSnackbarInfo(i18n.t("label_info_changed_configuration")))
            Object.keys(configurationDefaultSunnyCom).forEach((value) => {
              dispatch(updateState(value, configurationDefaultSunnyCom[value], false));
            })
            dispatch(updateCompatibleProducts(["sunnycom"]))
          }
        }
        break
      case "configurationSide":
        dispatch(setConfigurationSide(value))
        dispatch(changeIsFlipped(value !== 0))
        break
      case "configurationExterior_type":
        dispatch(setConfigurationExterior_type(value))
        break
      case "configurationExterior_material":
        dispatch(setConfigurationExterior_material(value))
        dispatch(setDoorOutsideMaterial(value))
        if (viewerUpdate) {
          dispatch(changeMaterial("outside", value));
        }
        break
      case "configurationInterior_material":
        dispatch(setConfigurationInterior_material(value))
        dispatch(setDoorInsideMaterial(value))
        if (viewerUpdate) {
          dispatch(changeMaterial("inside", value));
        }
        break
      case "configurationExterior_materialType":
        dispatch(setConfigurationExterior_materialType(value))
        break
      case "configurationInterior_materialType":
        dispatch(setConfigurationInterior_materialType(value))
        break
      case "configurationHandleExternal":
        dispatch(setConfigurationHandleExternal(value))
        if (viewerUpdate) {
          if (oldState.step.configurationHinge === 0) {
            dispatch(changeAccessories(["ManiglioneRotondo", "ManiglioneRotondaEsterno", "PulsanteOvale"], [oldState.step.configurationAccessories && value === 0 ? "PulsanteOvale" : "", steps[8].content.selectable_values[value].model]))
          } else {
            dispatch(changeAccessories(["ManiglioneQuadrato", "ManigliaQuadrataEsterno", "PulsanteQuadrato"], [oldState.step.configurationAccessories && value === 1 ? "PulsanteQuadrato" : "", steps[8].content.selectable_values[value].model]))
          }
        }
        break
      case "configurationHandleInternal":
        dispatch(setConfigurationHandleInternal(value))
        break
      case "configurationAutoCloser":
        dispatch(setConfigurationAutoCloser(value))
        break
      case "configurationAccessories":
        dispatch(setConfigurationAccessories(value))
        // IF ACCESSORY IS SELECTED - AUTOMATIC
        if (value) {
          // AND WAS MANUAL
          if (!oldState.step.configurationAccessories) {
            if (oldState.step.configurationHinge === 0) {
              // dispatch(changeAccessories(["SerraturaRotonda"], ["PulsanteOvale", value.model]))
              dispatch(changeAccessories([], [oldState.step.configurationHandleExternal === 0 ? "PulsanteOvale" : "", value.model]))
            } else {
              // dispatch(changeAccessories(["SerraturaQuadrata"], ["PulsanteQuadrato", value.model]))
              dispatch(changeAccessories([], [oldState.step.configurationHandleExternal === 1 ? "PulsanteQuadrato" : "", value.model]))
            }
          }
          // AND WAS ALREADY AUTOMATIC
          else {
            dispatch(changeAccessories([oldState.step.configurationAccessories.model], [value.model]))
          }
        }
        // IF MANUAL IS SELECTED
        else {
          // AND WAS MANUAL
          if (!oldState.step.configurationAccessories) {
            //NOTHING TO DO
          }
          // AND WAS AUTOMATIC
          else {
            if (oldState.step.configurationHinge === 0) {
              // dispatch(changeAccessories(["PulsanteOvale", oldState.step.configurationAccessories.model], ["SerraturaRotonda"]))
              dispatch(changeAccessories([oldState.step.configurationAccessories.model, "PulsanteOvale"], []))
            } else {
              // dispatch(changeAccessories(["PulsanteQuadrato", oldState.step.configurationAccessories.model], ["SerraturaQuadrata"]))
              dispatch(changeAccessories([oldState.step.configurationAccessories.model, "PulsanteQuadrato"], []))
            }
          }
        }
        break
      case "productSelect":
        dispatch(setProductSelected(value))
        break
      default:
        break;
    }

    const state = getState();

    // CHECK Width and Height to disable visibleHinge
    if (
      (key === "configurationWidth" || key === "configurationHeight") &&
      ((state.step.preselectedModel === "sunny" || state.step.preselectedModel === "sunnytop") ?
        state.step.configurationWidth <= steps[2].content.input_values[0].sunny_max_value &&
        state.step.configurationWidth >= steps[2].content.input_values[0].sunny_min_value &&
        state.step.configurationHeight <= steps[2].content.input_values[1].sunny_max_value &&
        state.step.configurationHeight >= steps[2].content.input_values[1].sunny_min_value
        :
        state.step.configurationWidth <= steps[2].content.input_values[0].max_value &&
        state.step.configurationWidth >= steps[2].content.input_values[0].min_value &&
        state.step.configurationHeight <= steps[2].content.input_values[1].max_value &&
        state.step.configurationHeight >= steps[2].content.input_values[1].min_value)
    ) {
      clearTimeout(timer);
      timer = setTimeout(() => {

        if (((state.step.preselectedModel === "sunny" || state.step.preselectedModel === "sunnytop") ?
          200 <= state.step.configurationHeight && state.step.configurationHeight <= 240 &&
          80 <= state.step.configurationWidth && state.step.configurationWidth <= 100
          :
          200 <= state.step.configurationHeight && state.step.configurationHeight <= 240 &&
          80 <= state.step.configurationWidth && state.step.configurationWidth <= 100
        )) {
          dispatch(setConfigurationVisibleHingeSupported(true));
        } else {
          dispatch(setConfigurationVisibleHingeSupported(false));
          if (state.step.configurationHinge === 0) {
            dispatch(setConfigurationHinge(1))
            // CHECK IF ALSO NEED SIZE CHANGE TO AVOID DOUBLE UPDATE
            dispatch(loadDefault(configurationDefaultSunnyCom,
              (state.step.configurationWidth <= 90 && state.viewer.size !== "Small") ||
              (state.step.configurationWidth >= 100 && state.viewer.size !== "Large") ||
              (90 < state.step.configurationWidth < 100 && state.viewer.size !== "Medium") ? false : true
            ));
            dispatch(setSnackbarInfo(i18n.t("label_info_changed_configuration")))
            Object.keys(configurationDefaultSunnyCom).forEach((value) => {
              dispatch(updateState(value, configurationDefaultSunnyCom[value], false));
            })
            dispatch(updateCompatibleProducts(["sunnycom"]))
          }
        }
        // UPDATE DOOR SIZE IN VIEWER SLICE
        if (state.step.configurationWidth <= 90
          // || state.step.configurationHeight <= 215
        ) {
          if (state.viewer.size !== "Small") {
            dispatch(updateModelSize("Small"))
          }
        } else if (state.step.configurationWidth >= 100
          // || state.step.configurationHeight <= 230
        ) {
          if (state.viewer.size !== "Large") {
            dispatch(updateModelSize("Large"))
          }
        } else {
          if (state.viewer.size !== "Medium") {
            dispatch(updateModelSize("Medium"))
          }
        }
      },
        timeToWaitMillisecond);
    }
    // const lastState = getState();
    // console.log("lastState",lastState)
  }
}

export const updatePreselectedModel = (model, loadModel = true) => (dispatch, getState) => {

  dispatch(setPreselectedModel(model))

  switch (model) {
    case "sunnycom":
      dispatch(updateCompatibleProducts(["sunnycom"]))
      dispatch(loadDefault(configurationDefaultSunnyCom, loadModel));
      Object.keys(configurationDefaultSunnyCom).forEach((value) => {
        dispatch(updateState(value, configurationDefaultSunnyCom[value], false));
      })

      break;
    case "habitat":
      dispatch(updateCompatibleProducts(["habitat"]))
      dispatch(loadDefault(configurationDefaultHabitat, loadModel))
      Object.keys(configurationDefaultHabitat).forEach((value) => {
        dispatch(updateState(value, configurationDefaultHabitat[value], false));
      })
      break;
    case "sunny":
      dispatch(updateCompatibleProducts(["sunny", "sunnytop"]))
      dispatch(loadDefault(configurationDefaultSunny, loadModel))
      Object.keys(configurationDefaultSunny).forEach((value) => {
        dispatch(updateState(value, configurationDefaultSunny[value], false));
      })
      break;
    case "sunnytop":
      dispatch(updateCompatibleProducts(["sunnytop", "sunny"]))
      dispatch(loadDefault(configurationDefaultSunny, loadModel))
      Object.keys(configurationDefaultSunny).forEach((value) => {
        dispatch(updateState(value, configurationDefaultSunny[value], false));
      })
      break;

    default:
      break;
  }

}

export const updateCompatibleProducts = (models) => (dispatch, getState) => {
  let tempModels = []

  models.forEach((modelName) => {
    if (steps[12].content.compatibleProducts[modelName]) {
      tempModels.push(steps[12].content.compatibleProducts[modelName]);
    }
  })

  dispatch(setCompatibleProducts([...tempModels]))
}



export default stepSlice.reducer
