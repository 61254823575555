import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { themeModes } from '../../slices/themeSlice'

export default function CircularBackdrop(props) {
  const { open } = props

  return (
    <Backdrop
      sx={{
        "@media print": {
          display:"none"
        },
        backgroundColor: theme => (theme.palette.mode === themeModes.light) ? '#ffffff20' : '#00000020',
        zIndex: theme => theme.zIndex.drawer + 101, // also above modals
        userSelect: "none"
      }}
      open={open}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
