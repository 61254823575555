import { BrowserRouter, Route, Routes } from "react-router-dom"
import NavigateTo from "../components/routing/NavigateTo"
import PageLayout from "../components/layout/PageLayout"
import ARLanding from "../pages/ARLanding"
import NotFound from "../pages/NotFound"
import { routes } from "./Routes"
import { Suspense, lazy } from "react"
import CircularBackdrop from "../components/backdrop/CircularBackdrop"
import UiComponents from "../pages/UiComponents"

const Home = lazy(() => import('../pages/Homepage'))

export default function AppRouter() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <NavigateTo />
      <Suspense fallback={<CircularBackdrop open />}>
        <Routes>
          <Route path={routes.home} element={<PageLayout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path={routes.UIComponents} element={<PageLayout />}>
            <Route index element={<UiComponents />} />
          </Route>
          <Route path={routes.ar} element={<ARLanding />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
