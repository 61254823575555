import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { unsetSnackbar } from "../../slices/snackbarSlice"
import { useSnackbar } from "notistack"

export default function SnackbarEnqueuer(props) {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { enabled, message, variant, persist } = useSelector(state => state.snackbar)

  useEffect(() => {
    if (enabled) {
      dispatch(unsetSnackbar())
      enqueueSnackbar(message, { variant, persist })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

}
