import { Button, ButtonGroup } from "@mui/material";
// import ListIcon from '@mui/icons-material/List';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import CheckIcon from '@mui/icons-material/Check';

export default function SwitchButtons(props) {

  const {
    listActive,
    toggleFunction,
  } = props;


  return (
    <ButtonGroup sx={{
      "& .MuiButtonBase-root": {
        borderRadius: "30px",
        p: "6px",
      },
      "& .MuiButtonGroup-firstButton, .MuiButtonGroup-middleButton": {
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
      },
      "& .MuiButtonGroup-lastButton, & .MuiButtonGroup-middleButton": {
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        marginLeft: "-1px",
      },
      "& .MuiButton-icon.MuiButton-startIcon": {
        marginRight: "0px",
      }
    }}
    disableElevation
    >
      <Button
      variant={listActive ? "contained" : "outlined"}
      startIcon={listActive ? <CheckIcon /> : ""}
      onClick={()=> {
        toggleFunction();
      }}
      >
          <FormatListBulletedIcon fontSize="20px" />
      </Button>
      <Button
      variant={listActive ? "outlined" : "contained"}
      startIcon={listActive ? "" : <CheckIcon />}
      onClick={()=> {
        toggleFunction();
      }}
      >
          <GridViewIcon  fontSize="20px"/>
      </Button>
    </ButtonGroup>
  )
}
