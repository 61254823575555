import { configureStore } from '@reduxjs/toolkit'
import resetReducer from '../slices/resetSlice'
import themeReducer from '../slices/themeSlice'
import snackbarReducer from '../slices/snackbarSlice'
import backdropReducer from '../slices/backdropSlice'
import loaderReducer from '../slices/loaderSlice'
import errorReducer from '../slices/errorSlice'
import navigationReducer from '../slices/navigationSlice'
import initHomepageReducer from '../slices/initHomepageSlice'
import stepReducer from '../slices/stepSlice'
import viewerReducer from '../slices/viewerSlice'
import formReducer from '../slices/formSlice'
import arReducer from '../slices/arSlice'

export const store = configureStore({
  reducer: {
    reset: resetReducer,
    theme: themeReducer,
    snackbar: snackbarReducer,
    backdrop: backdropReducer,
    loader: loaderReducer,
    error: errorReducer,
    navigation: navigationReducer,
    initHomepage: initHomepageReducer,
    step: stepReducer,
    viewer: viewerReducer,
    form: formReducer,
    ar: arReducer,
  }
})
