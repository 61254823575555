import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: ['en', 'it-IT', 'it', 'en-US'],
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      it: {
        translation: {
          label_darkmode: "Modalità scura",
          label_language: "Impostazione lingua",
          label_ar_cta: "Clicca per visualizzare il prodotto in AR",
          label_view_internal: "INTERNO",
          label_view_external: "ESTERNO",
          options_menu_title: "Impostazioni",
          label_email_error: "Verifica che la mail sia corretta.",
          label_environment_style: "Stile ambiente",
          label_environment_classic: "CLASSICO",
          label_environment_modern: "MODERNO",
          label_confirm: "Conferma",
          label_search_placeholder: "Cerca per nome o codice",
          label_filter_by_tag: "Filtra per tag",
          label_button_manual: "Manuale",
          label_button_automatic: "Automatico",
          label_chose_view: "scegli vista",
          label_technical_info: "Informazioni tecniche",
          label_discover_more: "Scopri di più",
          label_model_info_security: "Sicurezza antieffrazione",
          label_info_security_title: "Sicurezza antieffrazione",
          label_info_security_description: "Le classi di effrazione, o classi di sicurezza, indicano la capacità di resistenza delle porte blindate ai tentativi di effrazione. Per “effrazione” si intende la “forzatura dei sistemi di chiusura”: le porte blindate non sono tutte uguali, non tanto per l’aspetto estetico che può essere il più vario, ma per quanto concerne il grado di sicurezza che possono offrire. Le classi per uso civile sono identificate in una scala da 1 (min) a 4 (max), mentre le classi per uso specifico sono identificate in una scala da 5 a 6.",
          label_model_info_acoustic: "Isolamento acustico",
          label_info_acoustic_title: "Isolamento acustico",
          label_info_acoustic_description: "L’isolamento acustico (o insonorizzazione o fono isolamento) è quel ramo dell’acustica che si occupa di limitare la trasmissione del suono tra due ambienti. L’isolamento acustico applicato ai portoncini blindati consente di isolare la tua abitazione garantendo una sempre maggiore privacy e comfort abitativo.",
          label_model_info_thermal: "Isolamento termico",
          label_info_thermal_title: "Isolamento termico",
          label_info_thermal_description: "La trasmittanza termica indica la capacità di un corpo di farsi attraversare dal calore, quindi di energia, che passa da un ambiente caldo a un ambiente freddo. Viene espressa in W/m2 K. Più basso è il valore della trasmittanza termica, maggiore è l’isolamento termico offerto dalla porta blindata e minori sono i costi delle bollette energetiche. In Italia ogni comune ha una determinata zona climatica (identificata da lettere da A a F) e caratterizzata da uno specifico valore di trasmittanza termica, al fine di garantire l’isolamento termico in tutte le stagioni. Questo valore è importante perché influisce sull’isolamento termico della casa sia in inverno che in estate. In inverno si disperde il calore del riscaldamento ed in estate la frescura dell’aria condizionata.",
          label_model_info_air: "Tenuta all'aria",
          label_info_air_title: "Tenuta all'aria",
          label_info_air_description: "La tenuta all’aria è la caratteristica di una porta blindata chiusa di proteggere dalle infiltrazioni di aria quando si verificano differenze di pressione tra interno ed esterno. La permeabilità all’aria dei serramenti in generale si misura con classi da 1 a 4,dove la classe 4 è la migliore possibile e corrisponde ad una pressione dell’aria di 600 Pascal.",
          label_model_info_water: "Tenuta all'acqua",
          label_info_water_title: "Tenuta all’acqua",
          label_info_water_description: "La tenuta all’acqua di una porta blindata è un parametro importante che indica la capacità di un serramento di impedire il passaggio di acqua meteorica dall’esterno. Le porte blindate ottengono la classificazione quando non presentano passaggi d’acqua dopo essere stati sottoposti al getto idraulico e alla pressione atmosferica con specifici metodi di prova.",
          label_model_info_wind: "Tenuta al carico del vento",
          label_info_wind_title: "Tenuta al carico del vento",
          label_info_wind_description: "Per resistenza al carico del vento si intende la capacità di un portoncino blindato, sottoposto a forti pressioni o depressioni come quelle causate dal vento, di mantenere una deformazione ammissibile e conservare le proprietà iniziali salvaguardando la sicurezza degli utenti.",
          label_discover_less: "Scopri di meno",
          label_wich_model: "Quale modello preferisci?",
          label_configuration_recap: "Riepilogo configurazione",
          label_recap_opening_type: "Tipologia di apertura",
          label_recap_model: "Modello",
          label_recap_accessories: "Accessori",
          label_get_quote: "Preventivo",
          label_export: "Esporta",
          label_yes: "si",
          label_no: "no",
          label_abort: "annulla",
          label_send: "invia",
          label_continue: "continua",
          label_retry: "riprova",
          label_contact_us: "contattaci",
          label_finalize: "finalizza",
          label_ar_modal_loading: "Il modello AR è in fase di caricamento",
          label_ar_stop: "Interrompi generazione modello AR",
          label_reset_selection_title: "Hai scelto di re-inizializzare il configuratore",
          label_reset_selection_description: "Se continui tornerai all'inizio della configurazione e tutte le tue scelte andranno perdute, se annulli tornerai alla configurazione.",
          label_manual: "manuale",
          label_automatic: "automatica",
          label_warn_reset: "Se continui cancellerai tutte le selezioni.",
          label_ar_modal_title: "Visualizza in Realtà Aumentata",
          label_ar_modal_description: "Esporta e visualizza in Realtà Aumentata il lato Esterno della tua porta personalizzata. Inquadra il QR Code con il tuo telefono e visualizza la tua porta nell’ambiente in cui preferisci.",
          label_side_internal: "Lato Interno",
          label_side_external: "Lato esterno",
          label_start_configuration: "Inizia la configurazione",
          label_models_title: "Conosci già i prodotti Gasperotti?",
          label_models_content: "Velocizza il processo di configurazione scegliendo uno dei prodotti disponibili, oppure inizia a configurare da subito.",
          label_configure: "Configura",
          label_configuration_number: "Configurazione #",
          label_duration_title: "Creazione e Validità",
          label_duration_time: "Validità 15 gg • fino al",
          label_created_on: "Creato il",
          label_preview_configuration: "Preview della configurazione",
          label_configurable_products: "Prodotti configurabili",
          label_outside_view: "Vista dell'esterno",
          label_inside_view: "Vista dell'interno",
          label_products_compatibility: "Compatibilità prodotti",
          label_products_compatible_configuration: "Prodotti compatibili con la tua configurazione",
          cta_request_your_quote_at: "Richiedi il tuo preventivo a",
          mail_orders: "ordini@gasperotti.com",
          cta_find_the_closest_store_near_you: "Trova il negozio più vicino a te",
          link_stores: "gasperotti.com/punti-vendita",
          site_info: "© 2018 Gasperotti srl Via Fornaci 62 A/B 38068 Rovereto (TN) P.I. 01021270226 | T. +39 0464 435 353 | Email info@gasperotti.com",
          label_download_pdf_success: "Il PDF è stato generato correttamente, controlla nei download per recuperare il file.",
          label_applicant_data: "Dati richiedente",
          label_form_name: "Nome",
          label_form_name_placeholder: "es: Mario",
          label_field_required: "Il campo è obbligatorio",
          label_missing_field: "Mancano i seguenti campi obbligatori:",
          label_form_surname: "Cognome",
          label_form_surname_placeholder: "es: Rossi",
          label_form_email: "Email",
          label_form_email_placeholder: "mario.rossi@gmail.com",
          label_form_phone: "Telefono",
          label_form_phone_placeholder: "Il tuo numero di telefono fisso o cellulare",
          label_where_is_your_door: "Dove si trova la tua porta?",
          label_form_address: "Via",
          label_form_address_placeholder: "Piazza/Via/Corso dell'immobile",
          label_form_addressnumber: "Civico",
          label_form_addressnumber_placeholder: "Indica il numero civico",
          label_form_addresscap: "Cap",
          label_form_addresscap_placeholder: "es: 38068",
          label_form_city: "Comune",
          label_form_city_placeholder: "Indica il comune dell'immobile",
          label_form_province: "Provincia",
          label_form_province_placeholder: "- Scegli dall'elenco -",
          label_other_infos: "Altre informazioni",
          label_form_clienttype: "Che tipo di cliente sei?",
          label_form_clienttype_placeholder: "– Scegli dall'elenco –",
          label_select_private: "Privato",
          label_select_reseller: "Rivenditore",
          label_select_company: "Impresa",
          label_form_worktype: "Di che tipo di intervento si tratta?",
          label_form_worktype_placeholder: "– Scegli dall'elenco –",
          label_select_new: "Nuovo",
          label_select_renovation: "Ristrutturazione",
          label_select_replacement: "Sostituzione",
          label_form_nameconsultant: "Con chi dei nostri consulenti vendita Gasperotti sei già in contatto?",
          label_form_nameconsultant_placeholder: "Inserisci il nome",
          label_form_message: "Il tuo messaggio",
          label_form_message_placeholder: "Inviaci un breve messaggio...",
          label_load_image: "Carica un'immagine",
          label_upload_image: "Upload image",
          label_max_image_size: "Max 5 MB/Immagine",
          label_error_image_size: "L'immagine non rispetta il limite di dimensione.",
          label_privacy_data_protection: "Privacy e protezione dei dati",
          text_privacy_intro: "Tenendo presente che il mio consenso è puramente facoltativo, oltre che revocabile in qualsiasi momento:",
          label_privacy_text: "Ho preso visione dell'",
          label_form_privacy: "Checkbox privacy",
          privacy_link: "https://www.gasperotti.com/privacy-policy",
          lable_ar_landing_title: "Clicca per visualizzare il prodotto in AR",
          label_ar_landing_file_missing: "Querystring 'file' parameter is not defined",
          label_ar_landing_platform_not_supported: "Piattaforma non supportata",
          label_privacy_link_text: "Informativa in materia di trattamento dei dati personali",
          label_marketing_text: "Do il consenso al trattamento dei dati personali da parte di Gasperotti S.r.l. per l'invio di comunicazioni commerciali, promozionali e pubblicitarie, ricerche di mercato, vendita diretta o attraverso un rivenditore autorizzato, con modalità automatizzate (via mail) e tradizionali (posta, telefono con operatore), riguardante i prodotti e servizi offerti da Gasperotti S.r.l.",
          label_info_changed_configuration: "Al cambio di modello sono stati impostati accessorie e materiali di default.",
          label_request_sended: "Richiesta inviata correttamente",
          label_request_sended_description: "La tua richiesta di preventivo è stata inviata correttamente. A breve invieremo una email all’indirizzo da te indicato con tutti i dettagli.",
          label_back_to_home: "Torna alla home",
          label_otherwise: "Oppure",
          label_continue_to_configurate: "Continua a configurare",
          label_Tekno_Material: "Materiali Tekno",
          label_PVC_Material: "MDF",
          step_0_title: "Configuratore per portoncini.",
          step_0_description: "Vuoi progettare la porta d’ingresso dei tuoi sogni in pochi click? Niente di più facile: con il nostro configuratore.",
          step_1_description: "Tipo e senso di apertura",
          step_1_title: "Tipo di esposizione",
          step_1_content_text: "I modelli Habitat, Sunny, Sunny Com e Sunny Top sono prodotti idonei all’esposizione alle intemperie.",
          step_1_content_text_next: "Vai a Dimensioni",
          step_2_description: "Tipo e senso di apertura",
          step_2_title: "Dimensioni",
          step_2_input_title: "Dimensioni della porta",
          step_2_label_width: "Larghezza",
          step_2_error_width: "La larghezza massima supportata dai modelli Sunny è compresa tra 80 cm e 110 cm.",
          step_2_label_height: "Altezza",
          step_2_error_height: "L'altezza massima supportata dai modelli Sunny è compresa tra 200 cm e 248 cm.",
          step_2_selectable_title: "Visibilità Cerniere",
          step_2_label_visible: "A vista",
          step_2_label_hidden: "A scomparsa",
          step_3_title: "Verso di apertura",
          step_3_description: "Tipo e senso di apertura",
          step_3_label_left: "Verso l'interno",
          step_3_label_left_description: "Con cerniere a sinistra",
          step_3_label_right: "Verso l'interno",
          step_3_label_right_description: "Con cerniere a destra",
          step_4_title: "Pannello esterno",
          label_door_0: "Pannello senza vetro",
          label_door_1cd: "Pannello vetro centrale con bassorilievi",
          label_door_1c: "Pannello vetro centrale con bassorilievi",
          label_door_1l: "Pannello vetro decentrato",
          label_door_3c: "Pannello 3 vetri centrali",
          label_door_3l: "Pannello 3 vetri decentrati",
          label_door_4c: "Pannello 4 vetri centrali",
          label_door_4l: "Pannello 4 vetri decentrati",
          step_4_description: "Modello",
          step_5_title: "Pannello interno",
          step_5_description: "Modello",
          step_5_text: "La scelta del pannello interno è vincolata dalla forma scelta nel pannello esterno.",
          step_5_text_back: "Modifica pannello esterno",
          step_6_title: "Esterno porta",
          step_6_description: "Materiali e Colori",
          label_material_type_PVCcolor: "MDF",
          step_7_title: "Interno porta",
          step_7_description: "Materiali e Colori",
          steps_7_text_only_material_available: "Con l’opzione Cerniere a Vista hai a disposizione solamente 1 finitura. Per altri colori, cambiare l’impostazione con l’opzione “A scomparsa”.",
          steps_7_text_back_to_hinge: "Impostazioni Cerniera",
          step_8_title: "Modanatura esterna",
          step_8_description: "Accessori",
          name_external_handle_round_1: "Maniglione esterno rotondo",
          description_external_handle_round_1: "Acciaio Inox",
          name_external_handle_round_2: "Maniglia esterna rotonda",
          description_external_handle_round_2: "Acciaio Inox",
          name_external_handle_squared_1: "Maniglione esterno quadrato",
          description_external_handle_squared_1: "Acciaio Inox",
          name_external_handle_squared_2: "Maniglia esterna quadrata",
          description_external_handle_squared_2: "Acciaio Inox",
          step_9_title: "Modanatura interna",
          step_9_description: "Accessori",
          name_internal_handle_round: "Maniglia interna rotonda",
          description_internal_handle_round: "Acciaio Inox",
          name_internal_handle_squared: "Maniglia interna quadrata",
          description_internal_handle_squared: "Acciaio Inox",
          step_10_title: "Chiudi porta",
          step_10_description: "Accessori",
          step_10_text: "Il chiudiporta è un dispositivo essenziale per la sicurezza e la comodità nelle strutture moderne. Funziona attraverso un meccanismo che assicura la chiusura automatica delle porte, evitando correnti d'aria e mantenendo l'isolamento termico e acustico. La sua versatilità permette di adattarlo a diverse tipologie di situazioni, rendendolo ideale per un'ampia gamma di applicazioni, dalle abitazioni private ai complessi commerciali e pubblici.",
          step_11_title: "Chiusura porta",
          step_11_description: "Chiusura",
          step_11_text: "Potrai scegliere gli optional per Chiusura Manuale con un nostro consulente o con un rivenditore autorizzato.",
          name_accessory_01: "Tastierino Quadrato",
          name_accessory_02: "Tastierino Ovale",
          name_accessory_03: "Tastierino Vetrato",
          name_accessory_04: "Lettore Biometrico",
          name_accessory_05: "Boccola Lettore Nascosto",
          name_accessory_06: "Lettore RFID",
          step_12_title: "Concludi la tua configurazione",
          step_12_description: "Prodotti compatibili",
          label_product_description_name_sunnytop: "Sunny Top",
          label_product_description_security_sunnytop: "Classe 3",
          label_product_description_acoustic_sunnytop: "RW 43",
          label_product_description_thermal_sunnytop: "UD 1.0",
          label_product_description_air_sunnytop: "Classe 4 EN",
          label_product_description_water_sunnytop: "6A",
          label_product_description_wind_sunnytop: "Classe C4",
          label_product_description_name_sunny: "Sunny",
          label_product_description_security_sunny: "Classe 3",
          label_product_description_acoustic_sunny: "RW 43",
          label_product_description_thermal_sunny: "UD 1.4",
          label_product_description_air_sunny: "Classe 4 EN",
          label_product_description_water_sunny: "6A",
          label_product_description_wind_sunny: "Classe C4",
          label_product_description_name_habitat: "Habitat",
          label_product_description_security_habitat: "Classe 3",
          label_product_description_acoustic_habitat: "RW 43",
          label_product_description_thermal_habitat: "UD 0.84",
          label_product_description_air_habitat: "Classe 4 EN",
          label_product_description_water_habitat: "6A",
          label_product_description_wind_habitat: "Classe C4",
          label_product_description_name_sunnycom: "Sunny Com",
          label_product_description_security_sunnycom: "Classe 3",
          label_product_description_acoustic_sunnycom: "RW 43",
          label_product_description_thermal_sunnycom: "UD 1.08",
          label_product_description_air_sunnycom: "Classe 4 EN",
          label_product_description_water_sunnycom: "6A",
          label_product_description_wind_sunnycom: "Classe C4",
          step_13_title: "Richiedi preventivo e sopralluogo",
          label_mail_successfully_sent: "Mail inviata con successo",
          label_mail_unsuccessfully_sent: "C'è stato un problema con l'invio della mail, si prega di riprovare.",
          label_Aluminum_Material: "Allumini",
          label_thanks_for_your_configuration: "Grazie per la tua configurazione",
          label_your_data: "I tuoi dati",
        }
      },
      en: {
        translation: {
          label_darkmode: "Dark mode",
          label_language: "Language setting",
          label_ar_cta: "Click to display the product in AR",
          label_view_internal: "INTERIOR",
          label_view_external: "EXTERIOR",
          options_menu_title: "Settings",
          label_email_error: "Check that the e-mail address you entered is correct.",
          label_environment_style: "Outdoor and indoor style",
          label_environment_classic: "CLASSIC",
          label_environment_modern: "MODERN",
          label_confirm: "Confirm",
          label_search_placeholder: "Search by name or code",
          label_filter_by_tag: "Filtra per tag",
          label_button_manual: "Manual",
          label_button_automatic: "Automatic",
          label_chose_view: "Choose view",
          label_technical_info: "Technical information",
          label_discover_more: "Find out more",
          label_model_info_security: "Anti-theft security",
          label_info_security_title: "Anti-theft security",
          label_info_security_description: "Anti-theft security classes indicate the resistance capacity of security doors to break-in attempts. By 'break-in' we mean the 'forcing of locking systems': security doors are not all the same, not so much in terms of their aesthetic appearance, which can be the most varied, but in terms of the degree of security they can offer. Classes for civil use are identified on a scale of 1 (min) to 4 (max), while classes for specific use are identified on a scale of 5 to 6.",
          label_model_info_acoustic: "Sound insulation",
          label_info_acoustic_title: "Sound insulation",
          label_info_acoustic_description: "Acustic insulation (or soundproofing or soundproofing) is that branch of acoustics that deals with limiting the transmission of sound between two rooms. Acoustic insulation applied to armoured doors allows you to insulate your home, ensuring greater privacy and living comfort.",
          label_model_info_thermal: "Thermal insulation",
          label_info_thermal_title: "Thermal insulation",
          label_info_thermal_description: "Thermal transmittance indicates the ability of a body to have heat, i.e. energy, passing from a warm environment to a cold environment. It is expressed in W/m2 K. The lower the thermal transmittance value, the greater the thermal insulation offered by the security door and the lower the energy bill costs. In Italy, each municipality has a specific climatic zone (identified by letters from A to F) and characterised by a specific thermal transmittance value, in order to guarantee thermal insulation in all seasons. This value is important because it affects the thermal insulation of the house in both winter and summer. In winter, heat from heating is dispersed and in summer, coolness from air conditioning.",
          label_model_info_air: "Air tightness",
          label_info_air_title: "Air tightness",
          label_info_air_description: "Air tightness is the characteristic of a closed security door to protect against air infiltration when pressure differences occur between inside and outside. The air permeability of doors and windows in general is measured in classes 1 to 4, where class 4 is the best possible and corresponds to an air pressure of 600 Pascal.",
          label_model_info_water: "Water tightness",
          label_info_water_title: "Water tightness",
          label_info_water_description: "The watertightness of a security door is an important parameter indicating the ability of a door or window to prevent the passage of meteoric water from outside. Armoured doors achieve the classification when they are free of water penetration after being subjected to hydraulic jetting and atmospheric pressure using specific test methods.",
          label_model_info_wind: "Resistance to wind load",
          label_info_wind_title: "Resistance to wind load",
          label_info_wind_description: "Wind load resistance refers to the ability of an armoured door, when subjected to strong pressures or depressions such as those caused by wind, to maintain an admissible deformation and retain its initial properties while safeguarding the safety of users.",
          label_discover_less: "Find out less",
          label_wich_model: "Which model do you prefer?",
          label_configuration_recap: "Configuration summary",
          label_recap_opening_type: "Door opening type",
          label_recap_model: "Model",
          label_recap_accessories: "Accessories",
          label_get_quote: "Quote",
          label_export: "Export",
          label_yes: "yes",
          label_no: "no ",
          label_abort: "cancel",
          label_send: "send",
          label_continue: "continue",
          label_retry: "try again",
          label_contact_us: "contact us",
          label_finalize: "finalize",
          label_ar_modal_loading: "AR model is loading",
          label_ar_stop: "Stop loading AR model",
          label_reset_selection_title: "You have chosen to restart the configurator",
          label_reset_selection_description: "If you continue you will return to the beginning of the configuration and all your choices will be lost, if you cancel you will return to the configuration.",
          label_manual: "Manual",
          label_automatic: "Automatic",
          label_warn_reset: "If you continue you will delete all your selections.",
          label_ar_modal_title: "Augmented Reality view",
          label_ar_modal_description: "Export and visualise the exterior of your customised door in Augmented Reality. Scan the QR Code with your phone and display your door in your favourite environment.",
          label_side_internal: "Interior side",
          label_side_external: "Exterior side",
          label_start_configuration: "Start configuration",
          label_models_title: "Do you already know Gasperotti models?",
          label_models_content: "Speed up the setup process by choosing one of the available products, or start configuring right away.",
          label_configure: "Configure",
          label_configuration_number: "Configuration #",
          label_duration_title: "Creation and Validity",
          label_duration_time: "Valid for 15 days - until",
          label_created_on: "Created on",
          label_preview_configuration: "Configuration preview",
          label_configurable_products: "Configurable products",
          label_outside_view: "Exterior view",
          label_inside_view: "Interior view",
          label_products_compatibility: "Product compatibility",
          label_products_compatible_configuration: "Products compatible with your configuration",
          cta_request_your_quote_at: "Ask for your quote at",
          mail_orders: "ordini@gasperotti.com",
          cta_find_the_closest_store_near_you: "Find the store nearest you",
          link_stores: "gasperotti.com/punti-vendita",
          site_info: "© 2018 Gasperotti srl Via Fornaci 62 A/B 38068 Rovereto (TN) P.I. 01021270226 | T. +39 0464 435 353 | Email info@gasperotti.com",
          label_download_pdf_success: "The PDF has been created successfully, check downloads to recover the file.",
          label_applicant_data: "Applicant data",
          label_form_name: "Name",
          label_form_name_placeholder: "e.g. John",
          label_field_required: "The field is required",
          label_missing_field: "The following required fields are missing:",
          label_form_surname: "Surname",
          label_form_surname_placeholder: "e.g. Smith",
          label_form_email: "Email",
          label_form_email_placeholder: "john.smith@gmail.com",
          label_form_phone: "Phone",
          label_form_phone_placeholder: "Your landline or mobile phone number",
          label_where_is_your_door: "Where is your door?",
          label_form_address: "Address",
          label_form_address_placeholder: "Square/Street/Building avenue",
          label_form_addressnumber: "House number",
          label_form_addressnumber_placeholder: "Indicates the house number ",
          label_form_addresscap: "Postcode",
          label_form_addresscap_placeholder: "e.g. 38068",
          label_form_city: "City",
          label_form_city_placeholder: "Indicates the municipality of the building",
          label_form_province: "County",
          label_form_province_placeholder: "- Choose from the list -",
          label_other_infos: "More information",
          label_form_clienttype: "What kind of customer are you?",
          label_form_clienttype_placeholder: "- Choose from the list -",
          label_select_private: "Private",
          label_select_reseller: "Reseller",
          label_select_company: "Building company",
          label_form_worktype: "What kind of house operations are we talking about?",
          label_form_worktype_placeholder: "- Choose from the list -",
          label_select_new: "New",
          label_select_renovation: "Renovation",
          label_select_replacement: "Substitution",
          label_form_nameconsultant: "With whom of our Gasperotti sales consultants are you already in contact?",
          label_form_nameconsultant_placeholder: "Enter the name",
          label_form_message: "Your message",
          label_form_message_placeholder: "Send us a quick message...",
          label_load_image: "Upload an image",
          label_upload_image: "Upload image",
          label_max_image_size: "Max 5 MB/image",
          label_error_image_size: "The image does not respect the size limit.",
          label_privacy_data_protection: "Privacy and data protection",
          text_privacy_intro: "Bearing in mind that my consent is purely optional, as well as revocable at any time:",
          label_privacy_text: "",
          label_form_privacy: "Privacy checkbox",
          privacy_link: "https://www.gasperotti.com/privacy-policy",
          lable_ar_landing_title: "Click to view the product in AR",
          label_ar_landing_file_missing: "Querystring 'file' parameter is not defined",
          label_ar_landing_platform_not_supported: "Platform not supported",
          label_privacy_link_text: "Personal data processing disclusures",
          label_marketing_text: "I hereby consent to the processing of my personal data by Gasperotti S.r.l. for the purpose of sending commercial, promotional and advertising communications, market research, direct sales or through an authorised retailer, by automated (email) and traditional (mail, telephone with operator) means, concerning the products and services offered by Gasperotti S.r.l.",
          label_info_changed_configuration: "At model change, default accessories and materials were set.",
          label_request_sended: "Request sent correctly",
          label_request_sended_description: "Your quote request was successfully sent. We will shortly send an email to the address you provided with all the details.",
          label_back_to_home: "Back to home",
          label_otherwise: "Or",
          label_continue_to_configurate: "Continue configuring",
          label_Tekno_Material: "Tekno materials",
          label_PVC_Material: "MDF",
          label_door_0: "Panel without glass",
          label_door_1cd: "Panel with centred glass and decoration",
          label_door_1c: "Panel with centred glass",
          label_door_1l: "Panel with off-centre glass",
          label_door_3c: "Panel with three central glasses",
          label_door_3l: "Panel with three off-centre glasses",
          label_door_4c: "Panel with four central glasses",
          label_door_4l: "Panel with four off-centre glasses",
          step_0_title: "Entrance door configurator",
          step_0_description: "Want to design the front door of your dreams in just a few clicks? Nothing easier: with our configurator.",
          step_1_description: "Type and direction of opening",
          step_1_title: "Type of weather exposure",
          step_1_content_text: "Habitat, Sunny, Sunny Com and Sunny Top models are weatherproof products.",
          step_1_content_text_next: "Go to size",
          step_2_description: "Type and direction of opening",
          step_2_title: "Size",
          step_2_input_title: "Door size",
          step_2_label_width: "Width",
          step_2_error_width: "Sunny models support a maximum width between 80 cm and 110 cm.",
          step_2_label_height: "Height",
          step_2_error_height: "Sunny models support a maximum height between 200 cm and 248 cm.",
          step_2_selectable_title: "Door hinges visibility",
          step_2_label_visible: "Exposed hinges",
          step_2_label_hidden: "Hidden hinges",
          step_3_title: "Direction of the opening",
          step_3_description: "Type and direction of opening",
          step_3_label_left: "Inward",
          step_3_label_left_description: "Hinges on the left",
          step_3_label_right: "Inward",
          step_3_label_right_description: "Hinges on the right",
          step_4_title: "Outer panel",
          step_4_description: "Model",
          step_5_title: "Inside panel",
          step_5_description: "Model",
          step_5_text: "The choice of the inside panel is constrained by the shape chosen in the outside panel.",
          step_5_text_back: "Edit outside panel",
          step_6_title: "Outside door",
          step_6_description: "Materials and colors",
          label_material_type_PVCcolor: "MDF",
          step_7_title: "Inside door",
          step_7_description: "Materials and colors",
          steps_7_text_only_material_available: "With 'Exposed hinges' option you only have 1 finish available. For other colours, change the setting to the 'Hidden hinges' option.",
          steps_7_text_back_to_hinge: "Hinges settings",
          step_8_title: "Outside moulding",
          step_8_description: "Accessories",
          name_external_handle_round_1: "Round outside grab bar",
          description_external_handle_round_1: "Inox Steel",
          name_external_handle_round_2: "Round outside handle",
          description_external_handle_round_2: "Inox Steel",
          name_external_handle_squared_1: "Square outside grab bar",
          description_external_handle_squared_1: "Inox Steel",
          name_external_handle_squared_2: "Square outside handle",
          description_external_handle_squared_2: "Inox Steel",
          step_9_title: "Inside moulding",
          step_9_description: "Accessories",
          name_internal_handle_round: "Round inside handle",
          description_internal_handle_round: "Inox Steel",
          name_internal_handle_squared: "Square inside handle",
          description_internal_handle_squared: "Inox Steel",
          step_10_title: "Door closer",
          step_10_description: "Accessories",
          step_10_text: "Door closers are essential devices for safety and convenience in modern structures. They operate through a mechanism that ensures the automatic closing of doors, preventing drafts and maintaining thermal and acoustic insulation. Their versatility allows them to be adapted to various types of doors and settings, making them ideal for a wide range of applications, from private homes to commercial and public complexes.",
          step_11_title: "Door closer",
          step_11_description: "Closing",
          step_11_text: "You can choose the options for Manual Closing with our consultant or with an authorized reseller.",
          name_accessory_01: "Square keypad",
          name_accessory_02: "Oval keypad",
          name_accessory_03: "Glazed keypad",
          name_accessory_04: "Biometric reader",
          name_accessory_05: "Biometric reader bushing",
          name_accessory_06: "RFID reader",
          step_12_title: "Finish your configuration",
          step_12_description: "Compatible products",
          label_product_description_name_sunnytop: "Sunny Top",
          label_product_description_security_sunnytop: "Class 3",
          label_product_description_acoustic_sunnytop: "RW 43",
          label_product_description_thermal_sunnytop: "UD 1.0",
          label_product_description_air_sunnytop: "Class 4 EN",
          label_product_description_water_sunnytop: "6A",
          label_product_description_wind_sunnytop: "Class C4",
          label_product_description_name_sunny: "Sunny",
          label_product_description_security_sunny: "Class 3",
          label_product_description_acoustic_sunny: "RW 43",
          label_product_description_thermal_sunny: "UD 1.4",
          label_product_description_air_sunny: "Class 4 EN",
          label_product_description_water_sunny: "6A",
          label_product_description_wind_sunny: "Class C4",
          label_product_description_name_habitat: "Habitat",
          label_product_description_security_habitat: "Class 3",
          label_product_description_acoustic_habitat: "RW 43",
          label_product_description_thermal_habitat: "UD 0.84",
          label_product_description_air_habitat: "Class 4 EN",
          label_product_description_water_habitat: "6A",
          label_product_description_wind_habitat: "Class C4",
          label_product_description_name_sunnycom: "Sunny Com",
          label_product_description_security_sunnycom: "Class 3",
          label_product_description_acoustic_sunnycom: "RW 43",
          label_product_description_thermal_sunnycom: "UD 1.08",
          label_product_description_air_sunnycom: "Class 4 EN",
          label_product_description_water_sunnycom: "6A",
          label_product_description_wind_sunnycom: "Class C4",
          step_13_title: "Request a quote and inspection",
          label_mail_successfully_sent: "Email successfully sent",
          label_mail_unsuccessfully_sent: "There was a problem with sending the email, please try again.",
          label_Aluminum_Material: "Aluminum",
          label_thanks_for_your_configuration: "Thanks for your configuration",
          label_your_data: "Your data",
        }
      },
      de: {
        translation: {
          label_language: "Spracheinstellungen",
        }
      }
    }
  });

export default i18n;
