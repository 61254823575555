import { Box, Button, IconButton, Modal, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close'

export default function ModalReset(props) {

  const {
    isOpen,
    handleClose,
    handleContinue
  } = props;

  const theme = useTheme();

  const { t } = useTranslation();

  return(
    <Modal
    open={isOpen}
    onClose={handleClose}
    sx={{
      "@media print": {
        display: "none"
      }
    }}
    >
      <Box
      className="modalCT"
      sx={{
        background: theme.palette.background.default,
        borderRadius: "8px",
        maxWidth:"100%",
        width: "320px",
        position:"absolute",
        top: "50%",
        left: "50%",
        transform:"translate(-50%, -50%)",
        borderWidth: "0px",
        outline: "0px",
        [theme.breakpoints.up("lg")]: {
          width: "464px"
        }
      }}>
        {/*  HEADER */}
        <Box sx={{
          p: "16px 8px 16px 16px",
          borderRadius: "0px",
          borderBottom: `1px solid ${theme.palette.divider}`,
          display:"flex",
          alignItems: "center",
          justifyContent: "space-between",
          [theme.breakpoints.up("lg")]: {
            p: 3,
          }
        }}>
          <Typography variant="h6">{t("label_reset_selection_title")}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{
          display:"flex",
          flexDirection:"column",
          width:"100%",
          gap: 1,
          p: 2,
          [theme.breakpoints.up("lg")]: {
            p: 3,
          },
          img: {
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
            aspectRatio: 1.5,
          }
        }}>
            <Typography variant="body1">{t("label_reset_selection_description")}</Typography>
        </Box>
        <Box sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            width:"100%",
            p: 2,
            [theme.breakpoints.up("lg")]: {
              p: 3,
            },
          }}>
            <Button variant="outlined" onClick={handleClose} sx={{textTransform: "capitalize"}}>{t("label_abort")}</Button>
            <Button variant="contained" onClick={handleContinue} sx={{textTransform: "capitalize"}}>{t("label_continue")}</Button>
        </Box>

      </Box>
    </Modal>
  )
}
