import { Box, Button, Container, Switch, Typography, useTheme } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import SwitchButtons from "../components/layout/SwitchButtons";
import { toggleListView } from "../slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";

export default function UiComponents(props) {

  const theme = useTheme();

  const listView = useSelector(state => state.theme.listView)
  const dispatch = useDispatch();
  const handleToggleListView = () => dispatch(toggleListView())

  return (
    <Container sx={{
      border: "1px solid red",
      py: "80px"
    }}>
      <Grid2 container sx={{
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100svh",
      }} space={2}>
        <Grid2 xs={12}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap:"wrap",
            gap: 2,
            "& img":{
              display: "flex",
              maxWidth: "300px"
            }
          }}>
            <Box sx={{ display:"flex", flexGrow: 1, alignItems: "center", width: "100%", gap: "10px"}}>
              <img src={ process.env.PUBLIC_URL +"/assets/images/logo.svg"} alt="logo"></img>
              <Typography variant="subtitle1">logo.svg</Typography>
            </Box>
            <Box sx={{ display:"flex", flexGrow: 1, alignItems: "center", width: "100%", gap: "10px"}}>
              <img src={ process.env.PUBLIC_URL +"/assets/images/logo_colored.svg"} alt="logo_colored"></img>
              <Typography variant="subtitle1">logo_colored.svg</Typography>
            </Box>
            <Box sx={{ display:"flex", flexGrow: 1, alignItems: "center", width: "100%", gap: "10px"}}>
              <img src={ process.env.PUBLIC_URL +"/assets/images/logo_full_colored.svg"} alt="logo_full_colored"></img>
              <Typography variant="subtitle1">logo_full_colored.svg</Typography>
            </Box>
            <Box sx={{ display:"flex", flexGrow: 1, alignItems: "center", width: "100%", gap: "10px"}}>
              <img src={ process.env.PUBLIC_URL +"/assets/images/logo_long_colored.svg"} alt="logo_long_colored"></img>
              <Typography variant="subtitle1">logo_long_colored.svg</Typography>
            </Box>
          </Box>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h1">h1. Heading</Typography><br></br>
          <Typography variant="h2">h2. Heading</Typography><br></br>
          <Typography variant="h3">h3. Heading</Typography><br></br>
          <Typography variant="h4">h4. Heading</Typography><br></br>
          <Typography variant="h5">h5. Heading</Typography><br></br>
          <Typography variant="h6">h6. Heading</Typography><br></br>
          <Typography variant="subtitle1">subtitle1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
          <Typography variant="subtitle2">subtitle2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
          <Typography variant="body1">body1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
          <Typography variant="body1"><strong>body1. strong Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</strong></Typography><br></br>
          <Typography variant="body2">body2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
          <Typography variant="body2"><strong>body2. strong Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</strong></Typography><br></br>
          <Typography variant="button">BUTTON. Lorem ipsum dolor </Typography><br></br>
          <Typography variant="caption">caption. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
          <Typography variant="overline">overline. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
          <Typography variant="notes">notes. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis nihil in qui animi quam explicabo dolorem consectetur molestias.</Typography><br></br>
        </Grid2>
        <Grid2 xs={12} py={2}>
          <Box sx={{
            display:"flex",
            alignItems:"center",
            gap: 2,
            py: 2
          }}>
          <Switch></Switch>
          <SwitchButtons
            listActive={listView}
            toggleFunction={handleToggleListView}
          />
          <Button variant="text">
            Text
          </Button>
          <Button variant="text" sx={{textTransform:"uppercase"}}>
            Text
          </Button>
          <Button variant="contained" disableElevation>
            Contained
          </Button>
          <Button variant="contained" disableElevation sx={{textTransform:"uppercase"}}>
            Contained
          </Button>
          <Button variant="outlined">
            Outlined
          </Button>
          <Button variant="outlined" sx={{textTransform:"uppercase"}}>
            Outlined
          </Button>
          </Box>
        </Grid2>
        <Grid2 xs={12}>
          <Grid2 container>
          <Box sx={{
            aspectRatio: "1",
            display: "flex",
            flexGrow: "1",
            width: "25%",
            background: theme.palette.primary.main
          }}></Box>
          <Box sx={{
            aspectRatio: "1",
            display: "flex",
            flexGrow: "1",
            width: "25%",
            background: theme.palette.secondary.main
          }}></Box>
          <Box sx={{
            aspectRatio: "1",
            display: "flex",
            flexGrow: "1",
            width: "25%",
            background: theme.palette.background.paper
          }}></Box>
          <Box sx={{
            aspectRatio: "1",
            display: "flex",
            flexGrow: "1",
            width: "25%",
            background: theme.palette.text.primary
          }}></Box>
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  )
}
