import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loaderEnabled: false
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoader: state => {
      state.loaderEnabled = true
    },
    unsetLoader: state => {
      state.loaderEnabled = false
    }
  }
})

export const { setLoader, unsetLoader } = loaderSlice.actions

export default loaderSlice.reducer
