import { useState } from "react"
import { AppBar as MUIAppBar, Box, useTheme, Button } from "@mui/material"
// import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
// import { toggleThemeModePersist, longLogo } from "../../slices/themeSlice"
// import { logout } from "../../slices/authSlice"
import { appBarHeight, appBarHeightMobile } from "../../helpers/Layout"
// import { saveOrderNotes } from "../../slices/cartSlice"
import { toHomePage } from "../../actions/navigation"

import { getAssetImagesUrl } from "../../api/ApiDataHelper"
// import { ReactComponent as Logo } from "../../resources/logo.svg"
import SVG from 'react-inlinesvg'
// import { changeLanguage } from "i18next"
import ModalInfo from "./ModalReset"
import { setCurrentStep } from "../../slices/stepSlice"
import { useTranslation } from "react-i18next"

export default function AppBar(props) {
  const theme = useTheme()
  // const location = useLocation()
  const dispatch = useDispatch()
  // const cartOrder = useSelector(state => state.cart.cartOrder)
  // const userData = useSelector(state => state.auth.userData)
  // const userData = {
  //   _RivMaster: "true",
  //   _Name: "nome",
  //   _Surname: "congome",
  //   _Mail: "mail@di.prova"
  // }
  // const { volumeModeEnabled } = false

  // const [menuCollapse, setMenuCollapse] = useState(true)
  const [modalResetOpen, setModalResetOpen] = useState(false)

  // const handleToggleMenu = () => setMenuCollapse(!menuCollapse)

  const  { t } = useTranslation()

  // const handleClickMenuItem = page => {
  //   toPage(page)
  //   handleToggleMenu()
  // }

  // const handleToggleThemeMode = () => dispatch(toggleThemeModePersist())
  // const handleChangeLanguage = (lang) => dispatch(changeLanguage(lang))
  const longLogo = useSelector(state => state.theme.longLogo)
  const currentStep = useSelector(state => state.step.currentStep)
  // const handleLogout = () => dispatch(logout())


  return (
    <>
      <MUIAppBar
        elevation={0}
        // color='transparent'
        sx={{
          // borderBottom: theme.palette.mode === 'dark' ?
          //   '1px solid #333'
          //   : '1px solid #ddd',
          "@media print": {
            display: "none"
          },
          minHeight: appBarHeightMobile,
          position: "fixed",
          zIndex: theme => theme.zIndex.drawer + 1,
          padding: 2,
          background: currentStep === 0 ? "white" :"transparent",
          [theme.breakpoints.up("lg")]: {
            minHeight: appBarHeight,
            padding: 4,
            background:"transparent"
          }
        }}
      >
        <Box sx={{
          // '@media all': { minHeight: appBarHeight },
          p: 0,
          display: "flex",
          justifyContent: "space-between"
        }}>

          <Box
            sx={{
              height: "38px",
              boxSizing: "content-box",
              flexShrink: "0",
              "svg": {
                height: "100%",
                fill: theme.palette.background.default
              },
              "&:hover": {
                cursor: 'pointer'
              },
              [theme.breakpoints.up("lg")]: {
                height: "44px",
              },
              ".mobile": {
                display: currentStep === 14 ? "none" : "block",
                [theme.breakpoints.up("lg")]: {
                  display: "none",
                }
              },
              ".desktop": {
                display: "none",
                [theme.breakpoints.up("lg")]: {
                  display: currentStep === 14 ? "none" : "block",
                }
              },
              ".lastStep": {
                display: currentStep === 14 ? "block" : "none" ,
              }
            }}
            onClick={() => {setModalResetOpen(true)}}
          >
            {
              longLogo ?
              <>
                <SVG className="lastStep" src={getAssetImagesUrl("logo_long_colored.svg")} />
                <SVG className="mobile" src={getAssetImagesUrl("logo_long_colored.svg")} />
                <SVG className="desktop" src={getAssetImagesUrl("logo_long_white.svg")} />
              </>:
            <>
              <SVG className="lastStep" src={getAssetImagesUrl("logo_long_colored.svg")} />
              <SVG className="mobile" src={getAssetImagesUrl("logo.svg")} />
              <SVG className="desktop" src={getAssetImagesUrl("logo_long_white.svg")} />
            </>
            }
            {/* <Logo /> */}
          </Box>

          <Box sx={{
            display : currentStep >= 1 && currentStep < 12 ? "block" : "none",
          }}>
            <Button
            variant={ currentStep >= 4 ?  "contained" : "outlined"}
            color="primary"
            onClick={() => {  currentStep >= 4 ? dispatch(setCurrentStep(12)) : dispatch(setCurrentStep(13))}}
            sx={{
              textTransform: "uppercase",
              background: currentStep >= 4 ? "auto" : theme.palette.background.default,
            }}
            >
              {
                currentStep >= 4 ?
                  t("label_finalize")
                :
                  t("label_contact_us")
              }
            </Button>
          </Box>
          {/* <Box ml={1}>
            <UserButtonMenu
              disabled={volumeModeEnabled}
              darkTheme={theme.palette.mode === 'dark'}
              userData={userData}
              // handleResetSession={handleResetSession}
              handleToggleThemeMode={handleToggleThemeMode}
              handleChangeLanguage={handleChangeLanguage}
              // handleLogout={handleLogout}
            />
          </Box> */}
        </Box>
      </MUIAppBar>
      <ModalInfo
        isOpen={modalResetOpen}
        handleClose={() => {setModalResetOpen(false)}}
        handleContinue={toHomePage}
       />
    </>
  )
}
