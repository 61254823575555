import { MaterialDesignContent, SnackbarProvider } from "notistack"
import SnackbarCloseButton from "./SnackbarCloseButton"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SnackbarEnqueuer from "./SnackbarEnqueuer"
import { styled } from "@mui/material"

const StyledSnackbar = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main
  }
}))

export default function AppSnackbarProvider(props) {
  const { children } = props

  return (
    <SnackbarProvider
      maxSnack={3}
      dense
      action={snackKey => <SnackbarCloseButton snackKey={snackKey} />}
      iconVariant={{
        success: <CheckCircleOutlinedIcon fontSize='small' sx={{ mr: 1 }} />,
        error: <ErrorOutlineOutlinedIcon fontSize='small' sx={{ mr: 1 }} />,
        warning: <WarningAmberOutlinedIcon fontSize='small' sx={{ mr: 1 }} />,
        info: <InfoOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
      }}
      Components={{
        info: StyledSnackbar
      }}
    >
      <SnackbarEnqueuer />
      {children}
    </SnackbarProvider>
  )
}
