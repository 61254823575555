import { createSlice } from '@reduxjs/toolkit'
import { viewerInstance } from '../helpers/ViewerHelper'
import { setBackdrop, unsetBackdrop } from './backdropSlice'
import { getErrorMessage } from '../api/ApiDataHelper'
import { setScreenshotReady } from './stepSlice'
import { setSnackbarError, setSnackbarSuccess } from './snackbarSlice'
// import { setHomepageInitialized } from './initHomePageSlice'
import i18n from '../helpers/i18n';
import { unsetArLink } from './arSlice'
import { generatePdf } from '../helpers/PdfHelper'

const initialState = {
  sceneInitialized: false,
  currentObject: null,
  environmentStyle: "Modern",
  isCameraIn: false,
  isFlipped: false,
  doorModelName: "SUNNY-L_1_C",
  doorSize: "Small",
  doorAccessories: [
    "ManigliaRotondaInterna",
    "ManiglioneRotondo",
    "SerraturaRotonda",
  ],
  doorInsideMaterial: "",
  doorOutsideMaterial: "",
  screenShotInside: "",
  screenShotOutside: "",
  cameraIsMoving: false,
  isBusy: false,
  pdf: null,
}

let screenShots0 = null;
let screenShots1 = null;

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    resetViewerState: () => initialState,
    setSceneInitialized: (state, action) => {
      state.sceneInitialized = action.payload
    },
    setEnvironmentStyle: (state, action) => {
      state.environmentStyle = action.payload
    },
    setIsCameraIn: (state, action) => {
      state.isCameraIn = action.payload
    },
    setIsFlipped: (state, action) => {
     state.isFlipped = action.payload
    },
    setDoorSize: (state, action) => {
      state.doorSize = action.payload
     },
    setDoorModelName: (state, action) => {
      state.doorModelName = action.payload;
    },
    setDoorAccessories: (state, action) => {
      state.doorAccessories = action.payload;
    },setScreenShotInside: (state, action) => {
      state.screenShotInside = action.payload;
    },setScreenShotOutside: (state, action) => {
      state.screenShotOutside = action.payload;
    },setCameraIsMoving: (state, action) => {
      state.cameraIsMoving = action.payload;
    },setDoorInsideMaterial: (state, action) => {
      state.doorInsideMaterial = action.payload;
    },setDoorOutsideMaterial: (state, action) => {
      state.doorOutsideMaterial = action.payload;
    },setIsBusy: (state, action) => {
      state.isBusy = action.payload;
    },setPdf: (state, action) => {
      state.pdf = action.payload;
    },
  }
})

export const {
  resetViewerState,
  setSceneInitialized,
  setEnvironmentStyle,
  setIsCameraIn,
  setIsFlipped,
  setDoorSize,
  setDoorModelName,
  setDoorAccessories,
  setScreenShotInside,
  setScreenShotOutside,
  setCameraIsMoving,
  setDoorInsideMaterial,
  setDoorOutsideMaterial,
  setIsBusy,
  setPdf
} = viewerSlice.actions

export const loadScene = response => async (dispatch, getState) => {
  await viewerInstance.loadScene(response)

  dispatch(setSceneInitialized(true))
}

export const getEnvironmentStyle = () => (dispatch, getState) => {
  const tempEnvironmentStyle = viewerInstance.getEnvironmentStyle()
  dispatch(setEnvironmentStyle(tempEnvironmentStyle))
}

export const changeEnvironmentStyle = (environmentStyle) => async(dispatch, getState) => {
  viewerInstance.setEnvironmentStyle(environmentStyle)
  dispatch(setEnvironmentStyle(environmentStyle))
}


export const getIsCameraIn = () => (dispatch, getState) => {
  const tempIsCameraIn = viewerInstance.getIsCameraIn()
  dispatch(setIsCameraIn(tempIsCameraIn))
}

export const changeCamera = (goIn) => async (dispatch, getState) => {
  dispatch(setIsCameraIn(goIn))
  dispatch(setCameraIsMoving(true))
  await viewerInstance.changeView(goIn)
  dispatch(setIsCameraIn(goIn))
  dispatch(setCameraIsMoving(false))
}

export const getIsFlipped = () => (dispatch, getState) => {
  const tempIsFlipped = viewerInstance.isFlipped()
  dispatch(setIsFlipped(tempIsFlipped))
}

export const changeIsFlipped = (val) => async (dispatch, getState) => {
  await viewerInstance.flipConfiguration(val)
  dispatch(setIsFlipped(val))
  dispatch(unsetArLink())

}

export const updateModel = (doorModel) => async(dispatch, getState) => {
  const state = getState();
  dispatch(setIsBusy(true));
  if (doorModel) {
      await viewerInstance.update({
      modelName: doorModel,
      accessories: state.viewer.doorAccessories,
      size: state.viewer.doorSize,
    } , state.viewer.environmentStyle,
      state.viewer.doorInsideMaterial,
      state.viewer.doorOutsideMaterial,
  );

  }
  else {
   await viewerInstance.update( {
      modelName: state.viewer.doorModelName,
      accessories: state.viewer.doorAccessories,
      size: state.viewer.doorSize,
    }, state.viewer.environmentStyle,
      state.viewer.doorInsideMaterial,
      state.viewer.doorOutsideMaterial,
  );
  }
  dispatch(unsetArLink())
  dispatch(setIsBusy(false));
}

export const loadDefault = (defaultInfo, modelToUpdate = true) => async(dispatch, getState) => {
  dispatch(setDoorModelName(defaultInfo.doorModelName));
  dispatch(setDoorAccessories(defaultInfo.doorAccessories));
  dispatch(setDoorOutsideMaterial(defaultInfo.configurationExterior_material))
  dispatch(setDoorInsideMaterial(defaultInfo.configurationInterior_material))

  if(modelToUpdate) {
    await dispatch(updateModel())
  }
}

export const updateModelSize = (size) => (dispatch, getState) => {
  dispatch(setDoorSize(size))
  dispatch(updateModel())
}

export const changeMaterial = (side, materialName) => async(dispatch, getState) => {
  await viewerInstance.changeDoorMaterial(side, materialName);
  dispatch(unsetArLink())
}

export const updateScreenshots = (toDownload, useBackdrop) => async(dispatch, getState) => {
  try {
    if(useBackdrop){
      dispatch(setBackdrop())
    }
    dispatch(setScreenshotReady(false))
    // SLEEP 200 TO LET BACKDROP ANIMATION START BEFORE RENDERING
    await new Promise(r => setTimeout(r, 200));

    const screenShots = await viewerInstance.takeConfigurationScreenshots();

    if (screenShots && screenShots.length > 1) {

      if(screenShots0){
        URL.revokeObjectURL(screenShots0)
      }
      if(screenShots1){
        URL.revokeObjectURL(screenShots1)
      }

      screenShots0 = URL.createObjectURL(screenShots[0])
      screenShots1 = URL.createObjectURL(screenShots[1])

      dispatch(setScreenShotOutside(screenShots0))
      dispatch(setScreenShotInside(screenShots1))
    }
  }catch (error) {
    dispatch(setSnackbarError(getErrorMessage(error)))

  }finally {
    dispatch(setScreenshotReady(true))
    if(useBackdrop){
      dispatch(unsetBackdrop())
    }
    // SLEEP 200 TO LET BLOB SHOW UP IN DOM
    if (toDownload){
      setTimeout(async () => {
        await dispatch(handleGeneratePdf(true, useBackdrop))
      }, 200);
    }
    // else {
    //   setTimeout(async () => {
    //     return await dispatch(handleGeneratePdf(false, useBackdrop))
    //   }, 200);
    // }

  }
}

export const printPage = () => (dispatch, getState) => {
  window.print()
}

export const handleGeneratePdf = (download, useBackdrop) => async (dispatch, getState) => {

  if(useBackdrop){
    dispatch(setBackdrop())
  }

  await generatePdf(true);

  if(useBackdrop){
    dispatch(unsetBackdrop())
  }
  dispatch(setSnackbarSuccess(i18n.t("label_download_pdf_success")))
};

export const changeAccessories = (oldAccessories, newAccessories) => async (dispatch, getState) => {
  const tempAccessories = [...getState().viewer.doorAccessories];
  dispatch(setIsBusy(true));
  for(const oldAccessory of oldAccessories) {
    const index = tempAccessories.indexOf(oldAccessory);
    if (index >= 0){
      tempAccessories.splice(index, 1);
      }
    await viewerInstance.pullAccessory(oldAccessory, newAccessories.length > 0 ? false : true)
  }

  let newIndex = 0;
  for(const newAccessory of newAccessories) {
    const index = tempAccessories.indexOf(newAccessory);
    if (! (index >= 0)){
      tempAccessories.push(newAccessory);
    }
    await viewerInstance.pushAccessory(newAccessory, newAccessories.length > 1 && newIndex !== newAccessories.length-1 ? false : true, newAccessories.length > 1 && newIndex !== newAccessories.length-1 ? false : true);
    newIndex ++;
  }
  dispatch(setIsBusy(false));
  dispatch(unsetArLink())
  dispatch(setDoorAccessories(tempAccessories))
}

export default viewerSlice.reducer
