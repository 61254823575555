
const appBarHeight = 108
const appBarHeightMobile = 70
const appDrawerWidthMobile = 350
const catalogDrawerWidth = 420
const catalogDrawerWidthMobile = 350
const viewerDrawerWidth = 500
const ordersDrawerWidth = 500

export {
  appBarHeight,
  appBarHeightMobile,
  appDrawerWidthMobile,
  catalogDrawerWidth,
  catalogDrawerWidthMobile,
  viewerDrawerWidth,
  ordersDrawerWidth
}
