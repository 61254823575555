import jsPDF from "jspdf";
import { getFontUrl } from "../api/ApiDataHelper";
import { store } from "../store/store";
import { setPdfId } from "../slices/formSlice";


export const generatePdf = async (download) => {
  const pdfId = Date.now().toString(16);
  store.dispatch(setPdfId(pdfId))
  const content = document.getElementById("printable");

  if (content) {

    // Usa html2canvas per convertire l'elemento HTML in un canvas
    // const canvas = await html2canvas(content);
    // const imgData = canvas.toDataURL('image/jpg');

    // Crea un nuovo documento jsPDF
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a1',
     });

    pdf.addFont(getFontUrl('Jost-Bold.ttf'), 'Jost', 'bold');
    pdf.addFont(getFontUrl('Jost-Medium.ttf'), 'Jost', 'normal');
    pdf.addFont(getFontUrl('Jost-Light.ttf'), 'Jost', 'light');
    pdf.setFont('Jost');
    // let pageHeight = pdf.internal.pageSize.getHeight();
    let pageWidth = pdf.internal.pageSize.getWidth();
    content.style.width = (pageWidth - 100) + 'px';
    await pdf.html(content,{
      x: 50,
      y: 50,
      windowWidth: pageWidth - 100,
    });
    // const imgProps = pdf.getImageProperties(imgData);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    // console.log(pdfWidth, pdfHeight)
    // Aggiungi l'immagine al PDF
    // pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, "MEDIUM");

    // Salva il PDF
    if (download) {
      pdf.save('gasperotti_'+pdfId+'.pdf');
    } else {
      return pdf;
    }
  }
};
