import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { unsetNavigate } from "../../slices/navigationSlice"
import { useNavigate } from "react-router-dom"

export default function NavigateTo() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const navigateState = useSelector(state => state.navigation.navigate)

  useEffect(() => {
    if (navigateState.enabled) {
      dispatch(unsetNavigate())
      navigate(navigateState.to)
      navigate(0) // reload the page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateState])

}
