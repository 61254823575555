import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  navigate: {
    enabled: false,
    to: ''
  }
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigate: (state, action) => {
      state.navigate = {
        enabled: true,
        to: action.payload
      }
    },
    unsetNavigate: state => {
      state.navigate = initialState.navigate
    }
  }
})

export const { setNavigate, unsetNavigate } = navigationSlice.actions

export default navigationSlice.reducer
