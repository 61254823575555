import { createSlice } from "@reduxjs/toolkit";
import { updateScreenshots } from "./viewerSlice";
import { setBackdrop, unsetBackdrop } from "./backdropSlice";
import { generatePdf } from "../helpers/PdfHelper";
import api from "../api/Api";
import { setCurrentStep } from "./stepSlice";
import { setSnackbarError, setSnackbarSuccess } from "./snackbarSlice";
import i18n from '../helpers/i18n';
import { steps, materials } from "../helpers/StepsHelper";

const initialState = {
  formName: "",
  formName_error: false,
  formSurname: "",
  formSurname_error: false,
  formEmail: "",
  formEmail_error: false,
  formPhone: "",
  formPhone_error: false,
  formAddress: "",
  formAddress_error: false,
  formAddressNumber: "",
  formAddressNumber_error: false,
  formAddressCap: "",
  formAddressCap_error: false,
  formCity: "",
  formCity_error: false,
  formProvince: "",
  formProvince_error: false,
  formClientType: "",
  formClientType_error: false,
  formWorkType: "",
  formWorkType_error: false,
  formNameConsultant: "",
  formNameConsultant_error: false,
  formMessage: "",
  formMessage_error: false,
  formImg: "",
  formImg_error: false,
  formPrivacy: false,
  formPrivacy_error: false,
  formMarketing: false,
  formMarketing_error: false,
  pdfId: "",
}


export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormName: (state,action) => {
      state.formName = action.payload;
    },
    setFormName_error: (state,action) => {
      state.formName_error = action.payload;
    },
    setFormSurname: (state,action) => {
      state.formSurname = action.payload;
    },
    setFormSurname_error: (state,action) => {
      state.formSurname_error = action.payload;
    },
    setFormEmail: (state,action) => {
      state.formEmail = action.payload;
    },
    setFormEmail_error: (state,action) => {
      state.formEmail_error = action.payload;
    },
    setFormPhone: (state,action) => {
      state.formPhone = action.payload;
    },
    setFormPhone_error: (state,action) => {
      state.formPhone_error = action.payload;
    },
    setFormAddress: (state,action) => {
      state.formAddress = action.payload;
    },
    setFormAddress_error: (state,action) => {
      state.formAddress_error = action.payload;
    },
    setFormAddressNumber: (state,action) => {
      state.formAddressNumber = action.payload;
    },
    setFormAddressNumber_error: (state,action) => {
      state.formAddressNumber_error = action.payload;
    },
    setFormAddressCap: (state,action) => {
      state.formAddressCap = action.payload;
    },
    setFormAddressCap_error: (state,action) => {
      state.formAddressCap_error = action.payload;
    },
    setFormCity: (state,action) => {
      state.formCity = action.payload;
    },
    setFormCity_error: (state,action) => {
      state.formCity_error = action.payload;
    },
    setFormProvince: (state,action) => {
      state.formProvince = action.payload;
    },
    setFormProvince_error: (state,action) => {
      state.formProvince_error = action.payload;
    },
    setFormClientType: (state,action) => {
      state.formClientType = action.payload;
    },
    setFormClientType_error: (state,action) => {
      state.formClientType_error = action.payload;
    },
    setFormWorkType: (state,action) => {
      state.formWorkType = action.payload;
    },
    setFormWorkType_error: (state,action) => {
      state.formWorkType_error = action.payload;
    },
    setFormNameConsultant: (state,action) => {
      state.formNameConsultant = action.payload;
    },
    setFormNameConsultant_error: (state,action) => {
      state.formNameConsultant_error = action.payload;
    },
    setFormMessage: (state,action) => {
      state.formMessage = action.payload;
    },
    setFormMessage_error: (state,action) => {
      state.formMessage_error = action.payload;
    },
    setFormImg: (state,action) => {
      state.formImg = action.payload;
    },
    setFormImg_error: (state,action) => {
      state.formImg_error = action.payload;
    },
    setFormPrivacy: (state,action) => {
      state.formPrivacy = action.payload;
    },
    setFormPrivacy_error: (state,action) => {
      state.formPrivacy_error = action.payload;
    },
    setFormMarketing: (state,action) => {
      state.formMarketing = action.payload;
    },
    setFormMarketing_error: (state,action) => {
      state.formMarketing_error = action.payload;
    },
    setPdfId: (state,action) => {
      state.pdfId = action.payload;
    }
  }
});

export const {
  setFormName,
  setFormName_error,
  setFormSurname,
  setFormSurname_error,
  setFormEmail,
  setFormEmail_error,
  setFormPhone,
  setFormPhone_error,
  setFormAddress,
  setFormAddress_error,
  setFormAddressNumber,
  setFormAddressNumber_error,
  setFormAddressCap,
  setFormAddressCap_error,
  setFormCity,
  setFormCity_error,
  setFormProvince,
  setFormProvince_error,
  setFormClientType,
  setFormClientType_error,
  setFormWorkType,
  setFormWorkType_error,
  setFormNameConsultant,
  setFormNameConsultant_error,
  setFormMessage,
  setFormMessage_error,
  setFormImg,
  setFormImg_error,
  setFormPrivacy,
  setFormPrivacy_error,
  setFormMarketing,
  setFormMarketing_error,
  setPdfId
} = formSlice.actions;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const handleUpdateFormName = (val) => (dispatch, getState) => {
  dispatch(setFormName(val));
  dispatch(setFormName_error(val === ""));
}
export const handleUpdateFormSurname = (val) => (dispatch, getState) => {
  dispatch(setFormSurname(val));
  dispatch(setFormSurname_error(val === ""));
}
export const handleUpdateFormEmail = (val) => (dispatch, getState) => {
  dispatch(setFormEmail(val));
  const state = getState();
  if (state.form.formEmail_error) {
    dispatch(setFormEmail_error(!emailRegex.test(val)));
  }
}
export const handleUpdateFormPhone = (val) => (dispatch, getState) => {
  dispatch(setFormPhone(val));
  dispatch(setFormPhone_error(val === ""));
}
export const handleUpdateFormAddress = (val) => (dispatch, getState) => {
  dispatch(setFormAddress(val));
  // dispatch(setFormAddress_error(val === ""));
}
export const handleUpdateFormAddressNumber = (val) => (dispatch, getState) => {
  dispatch(setFormAddressNumber(val));
  // dispatch(setFormAddressNumber_error(val === ""));
}
export const handleUpdateFormAddressCap = (val) => (dispatch, getState) => {
  dispatch(setFormAddressCap(val));
  dispatch(setFormAddressCap_error(val === ""));
}
export const handleUpdateFormCity = (val) => (dispatch, getState) => {
  dispatch(setFormCity(val));
  dispatch(setFormCity_error(val === ""));
}
export const handleUpdateFormProvince = (val) => (dispatch, getState) => {
  dispatch(setFormProvince(val));
  dispatch(setFormProvince_error(val === ""));
}
export const handleUpdateFormClientType = (val) => (dispatch, getState) => {
  dispatch(setFormClientType(val));
  dispatch(setFormClientType_error(val === ""));
}
export const handleUpdateFormWorkType = (val) => (dispatch, getState) => {
  dispatch(setFormWorkType(val));
  dispatch(setFormWorkType_error(val === ""));
}
export const handleUpdateFormNameConsultant = (val) => (dispatch, getState) => {
  dispatch(setFormNameConsultant(val));
  dispatch(setFormNameConsultant_error(val === ""));
}
export const handleUpdateFormMessage = (val) => (dispatch, getState) => {
  dispatch(setFormMessage(val));
  dispatch(setFormMessage_error(val === ""));
}
export const handleUpdateFormImg = (val) => (dispatch, getState) => {
  dispatch(setFormImg(val));
  dispatch(setFormImg_error(val === ""));
}
export const handleUpdateFormPrivacy = (val) => (dispatch, getState) => {
  dispatch(setFormPrivacy(val));
  dispatch(setFormPrivacy_error(val === ""));
}
export const handleUpdateFormMarketing = (val) => (dispatch, getState) => {
  dispatch(setFormMarketing(val));
  dispatch(setFormMarketing_error(val === ""));
}

export const handleSandForm = (image) => async(dispatch, getState) => {
  dispatch(setBackdrop())
  //generate a fresh PDF
  await dispatch(updateScreenshots(false, false));
  const pdf = await generatePdf(false);

  const state = getState();

  let formData = new FormData()

  if (image) {
    formData.append('image', image)
  }
  if (pdf) {
    formData.append('pdf', pdf.output("blob", 'gasperotti_'+state.form.pdfId+'.pdf'))
  }
  let payload = JSON.stringify({
    "pdfId": state.form.pdfId,
    "thanksTitle": i18n.t("label_thanks_for_your_configuration"),
    "otherInfosLabel": i18n.t("label_other_infos"),
    "yourDataTitle": i18n.t("label_your_data"),
    "applicantDataTitle": i18n.t("label_applicant_data"),
    "whereIsYourDoorLabel": i18n.t("label_where_is_your_door"),
    "clientTypeLabel": i18n.t("label_form_clienttype"),
    "workTypeLabel": i18n.t("label_form_worktype"),
    "nameConsultantLabel": i18n.t("label_form_nameconsultant"),
    "messageLabel": i18n.t("label_form_message"),
    "privacyTitle": i18n.t("label_privacy_data_protection"),
    "privacyLabel": i18n.t("label_privacy_text") + " " + i18n.t("label_privacy_link_text"),
    "marketingLabel": i18n.t("label_marketing_text"),
    "formName": state.form.formName ? state.form.formName : "-" ,
    "formSurname": state.form.formSurname ? state.form.formSurname : "-" ,
    "formEmail": state.form.formEmail ? state.form.formEmail : "-" ,
    "formPhone": state.form.formPhone ? state.form.formPhone : "-" ,
    "formAddress": state.form.formAddress ? state.form.formAddress : "-" ,
    "formAddressNumber": state.form.formAddressNumber ? state.form.formAddressNumber : "-" ,
    "formAddressCap": state.form.formAddressCap ? state.form.formAddressCap : "-" ,
    "formCity": state.form.formCity ? state.form.formCity : "-" ,
    "formProvince": state.form.formProvince ? state.form.formProvince : "-" ,
    "formClientType": i18n.t(clientTypes[state.form.formClientType]),
    "formWorkType": i18n.t(workTypes[state.form.formWorkType]),
    "formNameConsultant": state.form.formNameConsultant ? state.form.formNameConsultant : "-",
    "formMessage": state.form.formMessage ? state.form.formMessage : "-",
    "formPrivacy": state.form.formPrivacy ? i18n.t("label_yes") : i18n.t("label_no"),
    "formMarketing": state.form.formMarketing ? i18n.t("label_yes") : i18n.t("label_no"),
    "configurationRecapTitle": i18n.t("label_configuration_recap"),
    "productTypeSizeSideTitle": i18n.t(steps[2].description),
    "productSizeLabel": i18n.t(steps[2].content.input_title),
    "productSizeValue": String(i18n.t(steps[2].content.input_values[0].label))[0].toUpperCase() + " " + state.step.configurationWidth + " cm • " + String(i18n.t(steps[2].content.input_values[0].label))[1].toUpperCase() + " " + state.step.configurationHeight + " cm",
    "productHingeLabel": i18n.t(steps[2].content.selectable_title),
    "productHingeValue": i18n.t(steps[2].content.selectable_values[state.step.configurationHinge].title),
    "productSideLabel": i18n.t(steps[3].title),
    "productSideValue": i18n.t(steps[3].content.selectable_values[state.step.configurationSide].title),
    "productModelTitle": i18n.t(steps[4].description),
    "productExternalPanelLabel": i18n.t(steps[4].title),
    "productExternalPanelValue": i18n.t(steps[4].content.selectable_values[state.step.configurationExterior_type].title),
    "productInternalPanelLabel": i18n.t(steps[5].title),
    "productInternalPanelValue": i18n.t(steps[4].content.selectable_values[state.step.configurationExterior_type].title),
    "productMaterialColorsTitle": i18n.t(steps[6].description),
    "productExternalMaterialLabel": i18n.t(steps[6].title),
    "productExternalMaterialValue": i18n.t(state.step.configurationExterior_materialType) + " • " + materials[state.step.configurationExterior_materialType][state.step.configurationExterior_materialCode],
    "productInternalMaterialLabel": i18n.t(steps[7].title),
    "productInternalMaterialValue": i18n.t(state.step.configurationInterior_materialType) + " • " + materials[state.step.configurationInterior_materialType][state.step.configurationInterior_materialCode],
    "productAccessoriesTitle": i18n.t(steps[8].description),
    "productExternalHandleLabel": i18n.t(steps[8].title),
    "productExternalHandleValue": i18n.t(steps[8].content.selectable_values[state.step.configurationHandleExternal]?.title),
    "productInternalHandleLabel": i18n.t(steps[9].title),
    "productInternalHandleValue": i18n.t(steps[9].content.selectable_values[state.step.configurationHandleInternal]?.title),
    "productAutoCloserLabel": i18n.t(steps[10].title),
    "productAutoCloserValue": i18n.t(state.step.configurationAutoCloser ? "label_yes" : "label_no"),
    "productAccessoryLabel": i18n.t(steps[11].title),
    "productAccessoryValue": i18n.t(state.step.configurationAccessories === false ? "label_manual" : "label_automatic") + (state.step.configurationAccessories === false ? "" : " • " + i18n.t(state.step.configurationAccessories.title)),
  })
  formData.append('payload', payload)

  // console.log(...formData);
  const res = await api.sendMail(formData)

  if (res){
    if(res.status && res.status === 200) {
      // console.log("Mail sent")
      dispatch(setSnackbarSuccess(i18n.t("label_mail_successfully_sent")))
      dispatch(setCurrentStep(14))
    }else{
      // console.log("Mail not sent")
      dispatch(setSnackbarError(i18n.t("label_mail_unsuccessfully_sent")))
      if (res.response?.data?.errors) {
        // console.log(res.response?.data?.errors)
        Object.keys(res.response.data.errors).forEach(element => {
          // console.log(element)
          dispatch(updateErrorAttribute(element));
        });
      }
    }
  }
  dispatch(unsetBackdrop())
}

export const updateErrorAttribute = (nameAttribute) => (dispatch, getState) => {
  // console.log(nameAttribute)
  switch (nameAttribute) {
    case "formName":
      dispatch(setFormName_error(true));
      break;
    case "formSurname":
      dispatch(setFormSurname_error(true));
      break;
    case "formEmail":
      // console.log("formEmail case", nameAttribute)
      dispatch(setFormEmail_error(true));
      break;
    case "formPhone":
      dispatch(setFormPhone_error(true));
      break;
    case "formAddress":
      dispatch(setFormAddress_error(true));
      break;
    case "formAddressNumber":
      dispatch(setFormAddressNumber_error(true));
      break;
    case "formAddressCap":
      dispatch(setFormAddressCap_error(true));
      break;
    case "formCity":
      dispatch(setFormCity_error(true));
      break;
    case "formProvince":
      dispatch(setFormProvince_error(true));
      break;
    case "formClientType":
      dispatch(setFormClientType_error(true));
      break;
    case "formWorkType":
      dispatch(setFormWorkType_error(true));
      break;
    case "formNameConsultant":
      dispatch(setFormNameConsultant_error(true));
      break;
    case "formMessage":
      dispatch(setFormMessage_error(true));
      break;
    case "formImg":
      dispatch(setFormImg_error(true));
      break;
    case "formPrivacy":
      dispatch(setFormPrivacy_error(true));
      break;
    case "formMarketing":
      dispatch(setFormMarketing_error(true));
      break;

    default:
      break;
  }
}

export default formSlice.reducer

export const clientTypes = {
  "label_select_private": "label_select_private",
  "label_select_reseller": "label_select_reseller",
  "label_select_company": "label_select_company",
}


export const workTypes = {
  "label_select_new": "label_select_new",
  "label_select_renovation": "label_select_renovation",
  "label_select_replacement": "label_select_replacement",
}

export const province ={
"Agrigento": "AG",
"Alessandria": "AL",
"Ancona": "AN",
"Aosta": "AO",
"Arezzo": "AR",
"Ascoli Piceno": "AP",
"Asti": "AT",
"Avellino": "AV",
"Bari": "BA",
"Barletta-Andria-Trani": "BT",
"Belluno": "BL",
"Benevento": "BN",
"Bergamo": "BG",
"Biella": "BI",
"Bologna": "BO",
"Bolzano": "BZ",
"Brescia": "BS",
"Brindisi": "BR",
"Cagliari": "CA",
"Caltanissetta": "CL",
"Campobasso": "CB",
"Carbonia-Iglesias": "CI",
"Caserta": "CE",
"Catania": "CT",
"Catanzaro": "CZ",
"Chieti": "CH",
"Como": "CO",
"Cosenza": "CS",
"Cremona": "CR",
"Crotone": "KR",
"Cuneo": "CN",
"Enna": "EN",
"Fermo": "FM",
"Ferrara": "FE",
"Firenze": "FI",
"Foggia": "FG",
"Forlì-Cesena": "FC",
"Frosinone": "FR",
"Genova": "GE",
"Gorizia": "GO",
"Grosseto": "GR",
"Imperia": "IM",
"Isernia": "IS",
"La Spezia": "SP",
"L'Aquila": "AQ",
"Latina": "LT",
"Lecce": "LE",
"Lecco": "LC",
"Livorno": "LI",
"Lodi": "LO",
"Lucca": "LU",
"Macerata": "MC",
"Mantova": "MN",
"Massa-Carrara": "MS",
"Matera": "MT",
"Messina": "ME",
"Milano": "MI",
"Modena": "MO",
"Monza e della Brianza": "MB",
"Napoli": "NA",
"Novara": "NO",
"Nuoro": "NU",
"Olbia-Tempio": "OT",
"Oristano": "OR",
"Padova": "PD",
"Palermo": "PA",
"Parma": "PR",
"Pavia": "PV",
"Perugia": "PG",
"Pesaro e Urbino": "PU",
"Pescara": "PE",
"Piacenza": "PC",
"Pisa": "PI",
"Pistoia": "PT",
"Pordenone": "PN",
"Potenza": "PZ",
"Prato": "PO",
"Ragusa": "RG",
"Ravenna": "RA",
"Reggio Calabria": "RC",
"Reggio Emilia": "RE",
"Rieti": "RI",
"Rimini": "RN",
"Roma": "RM",
"Rovigo": "RO",
"Salerno": "SA",
"Medio Campidano": "VS",
"Sassari": "SS",
"Savona": "SV",
"Siena": "SI",
"Siracusa": "SR",
"Sondrio": "SO",
"Taranto": "TA",
"Teramo": "TE",
"Terni": "TR",
"Torino": "TO",
"Ogliastra": "OG",
"Trapani": "TP",
"Trento": "TN",
"Treviso": "TV",
"Trieste": "TS",
"Udine": "UD",
"Varese": "VA",
"Venezia": "VE",
"Verbano-Cusio-Ossola": "VB",
"Vercelli": "VC",
"Verona": "VR",
"Vibo Valentia": "VV",
"Vicenza": "VI",
"Viterbo": "VT",
}
