import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { it } from "date-fns/locale"

export default function AppLocalizationProvider(props) {
  const { children } = props
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={it}
    >
      {children}
    </LocalizationProvider>
  )
}
