import { createSlice } from '@reduxjs/toolkit'
import { setErrorPage, unsetErrorPage } from './errorSlice'
import { setLoader, unsetLoader } from './loaderSlice'
import { getErrorMessage } from '../api/ApiDataHelper'
import { initViewer } from '../helpers/ViewerHelper'
import { setCurrentStep, updatePreselectedModel } from './stepSlice'

const initialState = {
  homepageInitialized: false
}

export const initHomepage = createSlice({
  name: 'initHomepage',
  initialState,
  reducers: {
    resetInitHomePageState: () => initialState,
    setHomepageInitialized: (state, action) => {
      state.homepageInitialized = action.payload
    }
  }
})

export const {
  resetInitHomePageState,
  setHomepageInitialized
} = initHomepage.actions

export const initializeHomepage = () => async (dispatch, getState) => {
  window.scrollTo(0, 0)
  dispatch(unsetErrorPage())
  const state = getState()
  const { homepageInitialized } = state.initHomepage
  if (!homepageInitialized) {
    dispatch(setLoader())
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const model = urlParams.get("model");
      if (model &&
        (
          model === "habitat" ||
          model === "sunny" ||
          model === "sunnycom" ||
          model === "sunnytop"
        )

      ) {
        // console.log(model);
        dispatch(updatePreselectedModel(model, false));
        dispatch(setCurrentStep(1));
      }

      await initViewer()

      dispatch(setHomepageInitialized(true))
    }
    catch (error) {
      // console.log(error)
      if (error.response?.status !== 401) {
        dispatch(setErrorPage({
          title: 'Errore richiesta server, impossibile inizializzare la pagina',
          message: getErrorMessage(error)
        }))
        throw error
      }
    }
    finally {
      dispatch(unsetLoader())
    }
  }
}

export default initHomepage.reducer
