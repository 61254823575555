import { Box, Button, Typography } from "@mui/material"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { appBarHeight } from "../helpers/Layout"
import { useTranslation } from "react-i18next";

export default function ErrorPage(props) {
  const {
    errorData,
    handleRetry
  } = props

  const { t } = useTranslation();

  return (
    <Box sx={{
      width: '100vw',
      height: `calc(80vh - ${appBarHeight}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: "absolute",
      pointerEvents: "auto",
      zIndex: 10000,
    }}>
      <ErrorOutlineOutlinedIcon color='error' sx={{ fontSize: 80 }} />
      <Typography variant='h5' mt={1} align='center'>
        {errorData.title}
      </Typography>
      <Typography variant='body2' mt={1}>
        {errorData.message}
      </Typography>
      <Button variant='outlined' sx={{ mt: 4 }} onClick={handleRetry}>
         {t("label_retry")}
      </Button>
    </Box>
  )
}
