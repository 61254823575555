// import { format } from "date-fns"
// import { it } from "date-fns/locale"
// import { getRuntimeConfig } from "../helpers/AppRuntimeConfig"
// import { getAuthToken } from "../helpers/AuthHelper"
import _ from "lodash"


export const sortRulesMessages = response => {
  let messages = response._FrontEntMode._Messages
  if (messages)
    messages = messages.sort((a, b) => _.toNumber(a._ID) - _.toNumber(b._ID))
}

export const splitDate = date => {
  return date ? date.split('T')[0] : ''
}

export const formatCurrency = (price, withSymbol = true, withDecimals = true) => {
  let priceFloat = price
  if (typeof price === 'string')
    priceFloat = parseFloat(price.replace(',', '.'))
  const currencyFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: withDecimals ? 2 : 0
  })
  let formattedNumber = currencyFormatter.format(priceFloat)
  return withSymbol ? formattedNumber : formattedNumber.replace('€', '').trim()
}

export const getAssetVideoUrl = filename =>
  process.env.PUBLIC_URL + '/assets/video/' + filename

export const getAssetImagesUrl = filename =>
  process.env.PUBLIC_URL + '/assets/images/' + filename

export const getFontUrl = filename =>
  process.env.PUBLIC_URL + '/assets/fonts/' + filename


export const getErrorMessage = error => {
  let errorMessage
  if (error) {
    errorMessage = [error.message]
    if (error.code) {
      errorMessage.unshift(error.code)
    }
    if (error.response) {
      const response = error.response
      if (response.config && response.config.url) {
        const url = '/' + response.config.url
        errorMessage.unshift(url)
      }
      if (response.data && response.data.Message) {
        const message = response.data.Message
        errorMessage.push(message)
      }
      if (response.data && response.data.ExceptionMessage) {
        const exceptionMessage = response.data.ExceptionMessage
        errorMessage.push(exceptionMessage)
      }
      if (response.data && response.data._ResponseMessage) {
        const responseMessage = response.data._ResponseMessage
        errorMessage.push(responseMessage)
      }
    }
    errorMessage = errorMessage.join(' ')
  }
  else {
    errorMessage = 'Unknown error'
  }
  return errorMessage
}
