import { Box, useTheme } from "@mui/material"
import "@lottiefiles/lottie-player"
// import { themeModes } from "../../slices/themeSlice"
// import { appBarHeight } from "../../helpers/Layout"
// import SVG from 'react-inlinesvg'
// import { getAssetImagesUrl } from "../../api/ApiDataHelper"
import Loader from "../../resources/logo_animation"
// import LoaderWhite from "../../resources/MIO-loader-white"

export default function LogoLoader(props) {
  const { loading } = props
  const theme = useTheme()

  return (
    <Box
      className="loaderContainer"
      sx={{
        "@media print": {
          display:"none"
        },
        position: "fixed",
        zIndex: theme.zIndex.drawer,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: theme.palette.background.default,
        pointerEvents: loading ? "auto" : "none",
        opacity: loading ? 1 : 0,
        transition: "0.3s all ease-out",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          // top: `calc(50% - ${appBarHeight}px)`,
          top: `50%`,
          left: "50%",
          transform: "translate(-50%, -50%)",
          gap: 3,
          alignItems: "center",
          maxWidth: "50%",
          width: "500px",
          svg: {
            fill: theme.palette.text.primary,
          }
        }}
      >
        {/* <SVG src={getAssetImagesUrl("logo.svg")} /> */}
        <lottie-player
          autoplay
          loop
          mode="normal"
          src={
            JSON.stringify(Loader)
          }
        />
      </Box>
    </Box>
  )
}
