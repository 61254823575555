import { createSlice } from '@reduxjs/toolkit'
import placeholder from "../resources/placeholder.png";

const initialState = {
  arLink: null,
  imageQRInterior: placeholder,
  imageQRExterior: placeholder,
  arLoading: false,
}

export const arSlice = createSlice({
  name: 'ar',
  initialState,
  reducers: {
    setArLink: (state, action) => {
      state.arLink = action.payload
    },
    setImageQRInterior: (state, action) => {
      state.imageQRInterior = action.payload
    },
    setImageQRExterior: (state, action) => {
      state.imageQRExterior = action.payload
    },
    setArLoading: (state, action) => {
      state.arLoading = action.payload
    }
  }
})

export const {
  setArLink,
  setImageQRInterior,
  setImageQRExterior,
  setArLoading
 } = arSlice.actions

export const unsetArLink = () =>  (dispatch, getState) => {
  dispatch(setArLink(null))
}

export default arSlice.reducer
