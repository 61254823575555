import axios from "axios"
import { store } from "../store/store"
import { setSnackbarError } from "../slices/snackbarSlice"
import { getErrorMessage } from "./ApiDataHelper"

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    store.dispatch(setSnackbarError(getErrorMessage(error)))
    return error
  }
)

// const testRequestBody = {
//   "model": "composizione_04",
//   "meshes": {
//       "posate": {
//           "material": "metallo"
//       },
//       "posate_tovagliolo_porta_posate": null,
//       "bicchieri_classici": {
//           "material": "vetro"
//       },
//       "bicchieri_osteria": null,
//       "piatto": {
//           "material": "ceramica"
//       },
//       "tovaglietta": null,
//       "runner": null,
//       "tovaglia": {
//           "material": "noise_100x100cm",
//           "texture": "88556200_tovaglia_mykonos_cioccolata.jpg"
//       },
//       "tovagliolo_quarto": {
//           "material": "noise_40x40cm",
//           "texture": "88400100_tovagliolo_bianco.jpg"
//       },
//       "tavolo_top": {
//           "material": "0964"
//       },
//       "tavolo_gambe": {
//           "material": "0964"
//       },
//       "busta_porta_posate": null,
//       "tovagliolo_porta_posate": null
//   }
// }

let keepPolling = true

const stopPolling = () => {
  keepPolling = false;
  return;
}

const generateARModel = async (modelState) => {
  keepPolling = true
  const generateARResponse = await generateAR(modelState)
  const requestId = generateARResponse.requestId
  return await poll(requestId)
}

const sleep = () => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 2000)
  })
}

const poll = async requestId => {
  // try {
  const getARResponse = await getAR(requestId)
  // console.log('getARResponse', getARResponse)
  if (getARResponse && getARResponse.ar_url) {
    return getARResponse.ar_url
  }
  else {
    if (keepPolling) {
      await sleep()
      return await poll(requestId)
    }
    else {
      keepPolling = true;
      return null;
    }
  }
  // }
  // catch (err) {
  //   await sleep()
  //   return await poll(requestId)
  //   // setTimeout(() => poll(requestId), 3000)
  // }
}

const getData = async () => {
  const params = {
    url: process.env.REACT_APP_API_ENDPOINT + '/data',
    method: "GET",
    headers: {
      'accept': 'application/json',
    }
  }
  const res = await axios.request(params)
  return res.data
}

const getAR = async requestId => {
  const params = {
    // url: process.env.REACT_APP_API_ENDPOINT + '/getAR',
    url: process.env.REACT_APP_API_ENDPOINT + '/ar',
    method: 'GET',
    params: {
      requestId
    },
    headers: {
      'accept': 'application/json',
    }
  }
  const res = await axios.request(params)
  return res.data
}

const generateAR = async (modelState) => {
  const params = {
    // url: process.env.REACT_APP_API_ENDPOINT + '/generateModelLambda',
    url: process.env.REACT_APP_API_ENDPOINT + '/ar',
    method: 'POST',
    data: modelState,
    headers: {
      'accept': 'application/json',
    }
  }
  // console.log("params", params)
  const res = await axios.request(params)
  return res.data
}

const sendMail = async formData => {
  const params = {
    // url: process.env.REACT_APP_API_ENDPOINT + '/getAR',
    url: process.env.REACT_APP_API_ENDPOINT + '/mail',
    method: 'POST',
    data: formData,
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  // console.log("params", params)
  const res = await axios.request(params)
  return res
}

// const createRequest = params => {
//   const requestParams = {
//     ...params,
//     baseURL: process.env.REACT_APP_BACKEND_URL,
//     headers: {
//       'accept': 'application/json',
//       ...params.headers,
//     },
//   }
//   return requestParams
// }



const api = {
  getData,
  generateARModel,
  stopPolling,
  sendMail
}

export default api
