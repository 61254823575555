import { closeSnackbar, useSnackbar } from "notistack"
import { useEffect } from "react"
import { snackbarVariants } from "../../slices/snackbarSlice"

export default function OfflineNotifier() {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    let snackbarId
    const updateStatus = event => {
      if (navigator.onLine) {
        if (snackbarId !== undefined) {
          closeSnackbar(snackbarId)
        }
      }
      else {
        snackbarId = enqueueSnackbar(
          'Nessuna connessione internet',
          {
            variant: snackbarVariants.error,
            persist: true
          }
        )
      }
    }
    window.addEventListener('online', updateStatus)
    window.addEventListener('offline', updateStatus)
    return () => {
      window.removeEventListener('online', updateStatus)
      window.removeEventListener('offline', updateStatus)
    }
  }, [enqueueSnackbar])

}
